.root-verification-container::before {
  content: "";
  background: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blur-background {
  filter: blur(2px);
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  margin: 0 auto;
  height: 100vh !important;
  overflow-y: auto !important;
  scrollbar-color: none;
  scrollbar-track-color: none;
}

.choose-plan-heading {
  position: absolute;
  top: 18%;
  font-family: PoppinsSemiBold;
  color: #FFFFFF;

  @media(width:1024px) {
    top: 8%;
  }
}

@media (min-width:1281px) and (max-width:1450px) {
  .choose-plan-heading {
    top: 12%;
  }
}

@media (min-width:866px) and (max-width:1050px) {
  .choose-plan-heading {
    top: 20% !important;
  }

  .choose-plan-option-container {
    margin-top: 10% !important;
  }
}

@media (min-width:1050px) and (max-width:1190px) {
  .choose-plan-heading {
    top: 14% !important;
  }
}

.choose-plan-option-container {
  margin-top: 4%;
  margin-left: 6%;
  margin-right: 6%;
  width: 88%;
  height: 300px;
  overflow: auto;
  scrollbar-width: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.choose-plan-list-container {
  width: calc(33.33% - 20px);
  min-width: 200px;
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.package-item-type {
  margin-block-end: 0em;
  margin-block-start: 0.7em;
}

.type-underline {
  border: none;
  border-bottom: 2px solid #FD9340;
  width: 86%;
  margin-left: 7%;
  margin-right: 7%;
}

.package-amount-styles {
  margin-block-start: 0.4em;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 90%;
  font-size: 1.5em;
  margin-block-end: 0em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.package-feature-styles {
  width: 100%;
  height: 42%;
  overflow: auto;
  margin-top: 1em;
}

.package-feature-styles ul {
  list-style-type: none;
  gap: 10px;
  padding-inline-start: 28px;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
  font-size: 14px;
  font-family: Poppins;
}

.package-button-container-styles {
  margin-top: 6%;
}

.package-button-styles {
  width: 185.83px;
  height: 34.1px;
  background-color: #FD9340;
  border-radius: 20px;
  border: none;
  font-family: PoppinsSemiBold;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-loader-styling {
  width: 40px;
  height: 40px;
}

.package-feature-styles li::before {
  content: '\2714';
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
  margin-left: -1em;
  margin-bottom: 0.6em;
}

.svgBack {
  height: 0px;
  width: 0px;
}

#modal-content {
  position: relative;
  display: flex;
  margin: 0 auto;
  min-width: 600px;
  width: 65%;
  max-width: 1000px;
  min-height: 460px;
  max-height: 1000px;
  margin-top: -220.5px;
  flex-shrink: 0;
  border-radius: 19px;
  background-color: #fd9340;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
  overflow: hidden;
}

.logo-containr {
  margin-top: 20px;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin-bottom: 13%;

  @media (min-width: 1500px)and (max-width: 3000px) {
    margin-bottom: 8%;
  }
}

.verification-logo-container {
  position: absolute;
  top: 2rem;
  z-index: 999;

  @media (min-width: 1500px)and (max-width: 3000px) {
    top: 5rem;
  }
}

.verification-text {
  font-size: 36px;
  padding: 0;
  margin: 0;
  width: 50%;
  max-height: -webkit-fill-available;
  text-align: center;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.textContainer {
  padding: relative;
  margin-top: 10%;
  width: 100%;
  font-family: "Poppins";
  display: flex;
  align-items: center;
  height: 80%;
  flex-direction: column;
}

.logoutButton {
  position: absolute;
  right: 3%;
  top: 20px;
  width: 80px;
  height: 45px;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 700;
  color: #fd9340;
  font-family: "Poppins";
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.refresh {
  position: absolute;
  right: 15%;
  top: 20px;
  width: 80px;
  height: 45px;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 700;
  color: #fd9340;
  font-family: "Poppins";
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.paragraphText {
  position: absolute;
  top: 55%;
  left: 30%;
  font-family: "Poppins";
  color: #fff;
  height: 20%;
  width: 40%;
  text-align: center;
  font-size: 18px;
}

.paragraphText-deactivate {
  position: absolute;
  top: 50%;
  left: 20%;
  font-family: "Poppins";
  color: #fff;
  height: 20%;
  width: 60%;
  text-align: center;
  font-size: 25px;
}

.verification-text-deactive {
  font-size: 36px;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: -webkit-fill-available;
  text-align: center;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-top: 1%;
}

.editText {
  position: absolute;
  top: 90%;
  color: #fff;
  font-family: "Poppins";
  font-size: 18px;
}

.edit-container-styles {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.edit {
  height: 26px;
}

.free-trail-plan-verification-text {
  width: 58%;
  font-size: 36px;
  padding: 0;
  margin: 0;
  height: auto;
  text-align: center;
  font-family: "PoppinsSemiBold";
  line-height: 140%;
}

.black-text {
  color: black;
}

.white-text {
  color: white;
}

.free-trail-plan-body-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buy-or-sell-text {
  text-align: center;
  font-size: 22px;
  margin-top: 2.2%;
}

.free-trail-description {
  text-align: center;
  width: 28%;
  margin-block-start: 2%;
  margin-block-end: 2%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.free-trail-plan-service {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.6%;
  margin-left: 4%;
  margin-right: 4%;
}

.icon-content {
  color: rgba(0, 0, 0, 0.55);
  font-size: 14px;
}

.free-trail-image-styles {
  position: absolute;
  right: 2%;
  top: 56%;
  transform: translateY(-50%);
}

.free-trail-image {
  width: 130px;
}

.free-trail-plan-button {
  background-color: #fff;
  border: none;
  border-radius: 100px;
  color: #130F26;
  font-family: "PoppinsSemiBold";
  width: 276px;
  height: 60px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.free-trail-api-loader {
  width: 60px;
  height: 60px;
}

.free-trail-plan-button-active {
  background-color: #130F26;
}

@media (max-width: 1189px) {
  .modal-container {
    overflow-y: hidden;
    width: 90%;
  }

  #modal-content {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    max-width: 99vh;
  }

  .refresh {
    position: absolute;
    right: 15%;
    top: 20px;
    width: 80px;
    height: 45px;
    padding: 10px 15px;
    margin-bottom: 15px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    color: #fd9340;
    font-family: "Poppins";
    border: none;
    border-radius: 15px;
  }

  .logo-containr {
    bottom: 5%;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
}

@media (max-width: 768px) {
  .free-trail-image-styles {
    display: none;
  }

  .free-trail-plan-verification-text {
    margin-top: 12%;
    font-size: 30px;
  }

  .buy-or-sell-text {
    font-size: 20px;
    margin-top: 3.6%;
  }

  .free-trail-description {
    width: 38%;
    gap: 1em;
    margin-block-start: 3.6%;
    margin-block-end: 3.6%;
  }

  .free-trail-plan-button {
    padding: 3% 3%;
  }

  .free-trail-plan-service {
    width: 60%;
    margin-top: 1.4%;
    align-items: flex-start;
  }
}

@media (max-width: 865px) {
  #modal-content {
    min-height: 100vh;
    max-height: auto;
    height: 100%;
    margin-top: 0%;
    flex-shrink: 0;
    border-radius: 0px;
  }

  .verification-text-deactive {
    width: 80%;
    text-align: center;
    margin-top: 10%;
  }

  .verification-text {
    font-size: 32px;
  }

  .free-trail-image-styles {
    display: none;
  }

  .editText {
    /* top: 440px; */
    top: 460px;
    width: fit-content;
    left: auto;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    height: 10%;
    gap: 58px
  }

  .edit-container-styles {
    gap: 58px !important;
  }

  .choose-plan-option-container {
    position: absolute;
    top: 60px;
    min-height: 340px;
    height: 340px;
  }

  .edit {
    position: absolute;
    right: 3%;
    bottom: 0vh;
    padding-bottom: 1%;
    height: 17px;
    top: 0.5em;
    width: 31.17px;
    height: 36px;
  }

  .refresh {
    width: 100px;
    height: 55px;
  }

  .svgBack {
    height: 100px;
    width: 80px;
    position: absolute;
    top: 0%;
  }

  .choose-plan-heading {
    position: absolute;
    top: 27px;
    font-size: 32px;
  }

  .package-item-type {
    margin-block-end: 0em;
    margin-block-start: 0.7em;
    font-size: 24px;
    font-family: PoppinsSemiBold;
  }

  .choose-plan-list-container {
    height: 330px;
  }


  .type-underline {
    margin-block-start: 0.3em;
    margin-block-end: 0em;
  }

  .package-amount-styles {
    margin-top: 12%;
  }

  .package-button-container-styles {
    margin-top: 0%;
  }

  .package-button-styles {
    width: 165.83px;
  }

  .package-feature-styles ul {
    margin-block-start: 3em;
    padding-inline-start: 32px;
  }

  .logo {
    height: 0px;
    width: 0px;
  }

  .logoutButton {
    width: 100px;
    height: 55px;
    position: absolute;
    top: 527px;
    bottom: 0px;
    right: 30%;
  }

  .deactivatedLogoutButtonStyle {
    width: 100px;
    height: 55px;
    position: absolute;
    top: 707px;
    bottom: 0px;
    right: 30%;
  }

  .modal-container {
    overflow-y: hidden;
    width: 100%;
    height: 100%;
  }

  #modal-content {
    position: fixed;
  }

  .textContainer {
    padding: 10px;
    margin-top: 9px;
  }

  .refreshContainer {
    position: absolute;
    top: 503px;
    right: 54%;
  }

  .deactivatedRefreshContainerStyle {
    position: absolute;
    top: 687px;
    right: 54%;
  }

  .paragraphText {
    top: 220px;
    width: 70%;
    left: auto;
    font-size: 24px;
    margin-left: 14px;
    margin-right: 14px;
  }

  .logo-containr {
    margin-bottom: 60%;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  .refresh {
    position: absolute;
    right: 27%;
  }
}

@media (width:912px) {
  .choose-plan-heading {
    top: 8%;
  }
}

@media (min-width:1281px) {
  #modal-content {
    min-height: 560px;
  }
}

@media (max-width:445px) {
  .choose-plan-option-container {
    gap: 10px;
  }

  .choose-plan-list-container {
    height: 330px;
    min-width: 33%;
  }

  .package-button-container-styles {
    margin-top: 7%;
  }

  .package-button-styles {
    width: 131.83px;
  }
}

@media (max-width:600px) {
  .svgBack {
    left: 6%;
  }
}

@media (max-width:500px) {
  .svgBack {
    left: 10%;
  }
}

@media (max-width:460px) {
  .svgBack {
    left: 16%;
  }
}

@media (max-width:414px) {
  .svgBack {
    left: 17%;
  }
}