.buyerContainer{
    background-color: #F8F8F8;
}

.heroSection{
    height: 400px;
    width: 100%;
    background-size: cover;
    margin: 0;
}

.heroContent{
    height: 50%;
    width: 25%;
    border-radius: 25px;
    background-color: #FD9340;
    margin-left: 3.5%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heroContent h1{
    color: white;
    font-family: 'PoppinsBold';
    padding: 0;
    margin: 0;
    font-size: 40px;
}

.itemsHeader{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.itemsHeader h1{
    padding: 0;
    margin: 0;
    margin-top: 13px;
    color: #FD9340;
    font-family: 'PoppinsBold';
    font-size: 25px;
}

.itemsHeader h6{
    padding: 0;
    margin: 0;
    margin-top: 7px;
    font-family: 'Poppins';
    font-size: 15px;
    padding-bottom: 30px;
}
