.cardsContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center; 
    width: 100%;
    flex-wrap: wrap;
}

.cards{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}
