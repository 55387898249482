.content-container {
    margin: initial;
    max-width: initial;
}

.main-root-container-refundpolicypage {
    overflow-y: auto;
    max-height: 99vh;
}

::-webkit-scrollbar {
    display: none;
}

@media screen and (min-width: 768px) {
    .content-container {
        margin-left: 5%;
        margin-right: 5%;
    }
}

.compalinace-content {
    background: #ffffff;
    padding: 32px 24px;
}

.content-head {
    font-size: 24px;
    line-height: 30px;
    color: #213554;
    margin: 0;
    font-weight: 700;
}

.content-seprater {
    width: 28px;
    height: 5px;
    background-color: #213554;
    margin-top: 16px;
}

.updated-date {
    margin: 16px 0 0;
    color: #213554ab;
    font-weight: 700;
}

.content-text {
    color: #515978;
    margin: 16px 0 0;
}

.content-text {
    font-size: 14px;
    line-height: 20px;
}

.list-item {
    display: list-item;
    padding-left: 5px;
}

.unorder-list {
    margin: 0;
}

.list-text {
    margin-top: 8px;
}

.mobile-back-button-container {
    display: none;
}

.link-refund {
    color: blue;
    text-decoration: underline;
}

@media (max-width:768px) {
    .refundpolicy-root-container-first {
        overflow-y: auto;
        background-color: rgb(10, 29, 56);
    }

    .refundpolicy-root-container-second {
        height: 24px;
    }

    .refundpolicy-root-container-third {
        padding: 0px;
        background-color: rgb(255, 255, 255);
        border-radius: 32px 32px 0px 0px;
    }

    .main-root-container-refundpolicypage {
        height: calc(100vh - 104px);
        overflow: scroll;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }

    .compalinace-page {
        background: #0a1d38;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: 400;
    }

    .compalinace-content {
        background: #ffffff;
        padding: 32px 24px;
    }

    .root-container-refundpolicypage {
        margin-bottom: 24px;
    }

    .mobile-back-button-container {
        display: block;
        visibility: visible;
        padding: 32px 24px 0px;
    }

    .main-button-container {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px currentcolor;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        font-family: PoppinsSemiBold;
        min-width: 64px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        padding: 12px 20px 12px 0px;
    }

    .span-div-style {
        display: inherit;
        margin-right: 8px;
        margin-left: -4px;
    }

    .backpress-text-style {
        margin: 0px;
        font-size: 18px;
        line-height: 30px;
        color: #213554;
        margin: 0;
        font-weight: 700;
    }

    .span-container-css {
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        z-index: 0;
        inset: 0px;
        border-radius: inherit;
    }
}