.helpPage-modal-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpPage-container {
    background-color: white;
    width: 518px;
    height: 883px;
    max-height: 95vh;
    overflow-y: auto;
}

.helpPage-top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.scrollableContainer {
    max-height: calc(95vh - 20vh);
    overflow-y: auto;
}

.helpPage-heading-container {
    display: flex;
    flex-direction: column;
}

.back-press-container {
    position: absolute;
}

.help-heading-text {
    width: 100%;
    color: #000;
    font-family: PoppinsBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 75.02px;
    text-align: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.typeOf-category-description {
    color: var(--greyscale-black-30, #B3B1B0);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-block-start: 0em;
    margin-block-end: 0em;
    position: relative;
    bottom: 0rem;
    margin-bottom: 1rem;
}

.categoryTitle {
    color: #000;
    font-family: PoppinsBold;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
}

.categoryOverView {
    color: #000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 580;
    line-height: 160%;
}

.individual-category-container {
    width: 90%;
    height: fit-content;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 3%;
}

@media (max-width: 768px) {
    .helpPage-container {
        background-color: white;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
    }

    .scrollableContainer {
        max-height: calc(100vh - 20vh);
        overflow-y: auto;
    }

    .helpPage-heading-container {
        margin-top: 2%;
    }
}

@media (min-width: 1400px) and (max-width:2000px) {
    .helpPage-container {
        background-color: white;
        width: 518px;
        height: 883px;
        max-height: 95vh;
    }
}