@font-face {
  font-family: 'PoppinsBold'; 
  src: url('../../src/fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsSemiBold'; 
  src: url('../../src/fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins'; 
  src: url('../../src/fonts/Poppins-Regular.ttf') format('truetype');
}