.dropdown-header {
  display: flex;
  align-items: center;
  margin-top: 16px;
  display: flex;
  padding: 5px;
  padding-right: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  padding-inline: 10px;
  margin-bottom: 20px;
}

.parameterparams-text-m1 {
  color: #5E5C5A;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  min-width: 110px;
}

.dropdown-input-container {
  width: 100%;
  display: flex;
}

.main-dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.dropdownparam-options {
  width: fit-content;
  min-width: 160px;
  /* height: 146.644px; */
  flex-shrink: 0;
  border-radius: 6.301px;
  background: #E5E4E3;
  z-index: 2;
  position: absolute;
  margin-top: 23px;
  max-width: 100%;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dropdown-option-parameter {
  display: flex;
  padding: 5px;
}

.dropdown-icon {
  margin-left: -20px;
}

.dropdownparam-options div:hover {
  background-color: #FFF5EC;
  cursor: pointer;
}

.squaredrop {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  /* position: absolute; */
}

.dropdown-textnew-style {
  margin-left: 15px;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.rippledropdown {
  position: relative;
  overflow: hidden;
  cursor: auto;
  transition: background-color 0.3s;
}

.dropdownparam-options::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.dropdownparam-options::-webkit-scrollbar-thumb {
  background-color: #E5E4E3;
  border-radius: 5px;
  border: none;
}

.dropdownparam-options::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  width: 10px;
}

.containerdropdownparam-style {
  width: 100%;
  height: 19px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  border: none;
  color: #5E5C5A;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  outline: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .dropdown-header {
    width: 267px;
  }
}