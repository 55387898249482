.paramroot-container {
    /* width: 70%; */
    display: flex;
}

.dropdownum-container {
    background-color: #B3B1B0;
}

.containerParam {
    height: 19px;
    flex-shrink: 0;
    margin-left: 4px;
    border: none;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    width: 100%;
    color: #5E5C5A;
}

.dropdownUm-options {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 6.301px;
    background: #E5E4E3;
    position: absolute;
    margin-top: 20px;
    margin-left: 5px
}

.um-drop-option-umDropDown:hover {
    color: black;
}

.um-drop-option-selected {
    /* background-color: #FD9340; */
}

.dropdownUm-text-style {
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    cursor: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ripplenew {
    /* width: 100px; */
    height: 40px;
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple-animation 0.4s;
    background-color: rgba(0, 0, 0, 0.3);
}

.squaredropsecond {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 8%;
}

.quantity-dropdown-option-container {
    display: flex;
    padding: 6%;
    justify-content: flex-start;
}

.dropdownUm-options div:hover {
    background-color: #FFF5EC;
    cursor: pointer;
}

.containerParamdropdownnew-style-um1 {
    /* width: 122.27px; */
    height: 19px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    border: none;
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    border: none;
}

.containerParamdropdownnew-style-um1:focus {
    outline: none;
}

@media (max-width: 768px) {
    .dropdownUm-text-style{
        margin-left: 14%;
    }
}