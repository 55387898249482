.modal-background-fillproduct {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.descrption-error-message {
  color: red;
  font-size: 12px;
  font-family: Poppins;
  display: block;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  min-width: 110px;
}

.fill-product-container {
  background-color: #FFFFFF;
  flex-shrink: 0;
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 17px;
  padding-left: 21px;
}

.fill-product-container::-webkit-scrollbar {
  width: 0;
}

.fill-product-header {
  display: flex;
  align-items: center;
}

.fill-product-back-container {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.07);
}

.back-button {
  display: flex;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.catogery-heading-text {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 147%;
  padding-left: 38px;
}

.fill-product-body {
  margin-top: 40px;
  margin-inline: 26px;
}

.root {
  width: 100%;
  display: flex;
  justify-content: center;
}

.prop-style {
  width: 420px;
  height: 29px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 47px;
  margin-top: 63px;
  padding-left: 5px
}

.props-text-style {
  color: #5E5C5A;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin-left: 2px;
}

.container-style {
  width: 301.27px;
  height: 19px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  border: none;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* 16px */
  margin-left: 20px;
  margin-top: 4px;
  padding-left: 10px;
}

.container-style {
  outline: none;
}

.propdescrption-style {
  /* width: 420px; */
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-top: 16px;
  display: flex;
  padding: 5px;
  padding-right: 10px;
}

.descrtiption-input-title {
  min-width: 110px;
}

.description-text-container {
  width: 100%;
  display: block;
}

.containerdescrption-style {
  width: 100%;
  height: 91px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  border: none;
  color: #5E5C5A;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.containerdescrption-style::-webkit-resizer {
  display: none;
}

.containerdescrption-style {
  outline: none;
}

.containerPrice-style {
  width: 120.27px;
  height: 19px;
  flex-shrink: 0;
  border: none;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  border-radius: 5px;
  margin-left: 55px;
  margin-top: 5px;
  padding-left: 10px;
}

.containerPrice-style {
  outline: none;
}

.containerPricedropdown-style-updated {
  width: 124.27px;
  height: 19px;
  flex-shrink: 0;
  border: none;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  border-radius: 5px;
  margin-left: 244px;
  margin-top: 2%;
  outline: none;
  padding-left: 7px;
}

.containerPricevalue-style {
  outline: none;
  border: none;
  margin-left: 250px;
}

.image-container-style {
  height: 236px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  padding-inline: 6.47px;
  padding-block: 13px;
}

.gallery-style {
  float: right;
}

.custom-param-style {
  width: 425px;
  height: auto;
  flex-shrink: 0;
  border-radius: 5px;
  background: #F8F8F8;
  margin-left: 47px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.go-back-link {
  cursor: auto;
}

.custom-style {
  border: none;
  width: 160px;
  text-align: center;
  height: 20px;
  outline: none;
  color: #B3B1B0;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.customvalue-style {
  border: none;
  width: 160px;
  text-align: center;
  height: 20px;
  outline: none;
  border-radius: 5px;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.svg-container {
  margin-left: 190px;
}

.button-style {
  border: none;
  background: none;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;
}

.selected-images-container {
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  background: #D9D9D9;
  margin-left: 8px;
}

.delete-icon {
  margin-left: 73px;
  position: relative;
  margin-top: -99px;
}

.selected-images-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.selected-image {
  width: 100%;
  height: 100%;
}

.fill-product-content-container {
  padding-bottom: 59px;
  margin-bottom: 59px;
}

.fill-product-content-container-data {
  padding-bottom: 59px;
}

.button-container-1 {
  display: inline-flex;
  padding: 14px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  background: var(--main-color-100, #FD9340);
  margin-top: 94px;
  border: none;
  float: right;
  cursor: pointer;
}

.request-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--main-color-100, #FD9340);
  margin-top: 94px;
  border: none;
  float: right;
  cursor: pointer;
  width: 168px;
  height: 54px;
}

.request-button-container img{
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-text-style {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* 25.6px */
}

.dropdown-options {
  width: 295.004px;
  height: 146.644px;
  flex-shrink: 0;
  border-radius: 6.301px;
  background: #E5E4E3;
  margin-left: 23%;
  margin-top: -4%;
  z-index: 1;
}

.containergrade-style {
  width: 301.27px;
  height: 19px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  border: none;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin-left: 20px;
  margin-top: 4px;
  padding-left: 10px;
}

.square {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  position: absolute;
  margin-top: -7px;
}

.dropdown-option-text {
  margin-left: 44%;
  margin-top: 0%;
  color: #B3B1B0;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.ripple-container {
  position: relative;
  overflow: hidden;
  cursor: auto;
  transition: background-color 0.3s;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-animation 0.4s;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 10%;
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.option-container {
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.selected {
  background-color: orange;
}

.dropdown-options {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dropdown-options::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.dropdown-options::-webkit-scrollbar-thumb {
  background-color: #E5E4E3;
  border-radius: 5px;
}

.dropdown-options::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  width: 10px;
}

.dropdown-m1 {
  width: 128.004px;
  height: 90.644px;
  flex-shrink: 0;
  border-radius: 6.301px;
  background: #E5E4E3;
  margin-left: -134%;
}

.dropdown-item {
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  padding-top: 17px;
}

.squaresecond {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  position: absolute;
  margin-top: 12px;
}

.price-text-style {
  padding-top: 10px;
  color: #B3B1B0;
}

@media (max-width: 768px) {
  .modal-background-fillproduct {
    /* background-color: rgb(255, 255, 255); */
  }

  .fill-product-container {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    /* justify-content: center; */
    align-items: center;
    align-self: center;
    /* display: flex; */
    /* flex-direction: column; */
  }

  .parameters-container {
    margin-top: 29px;
  }

  .fill-product-body {
    width: fit-content;
  }

  .fill-product-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .fill-product-header {
    width: 100%;
  }

  .image-container-style {
    height: 295px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    padding-inline: 6.47px;
    padding-block: 13px;
  }

  .image-handler-container {
    width: 220px;
    padding-inline: 5px;
    margin-left: 20px;
  }

  .selected-images-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 210px;
    width: 210px;
    margin-top: 27px;
  }

  .gallery-style {
    padding-top: 17px;
    padding-right: 5px;
  }

  .propdescrption-style {
    display: flex;
    flex-direction: column;
    /* width: 420px; */
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    margin-top: 16px;
    display: flex;
    padding: 5px;
    padding-right: 10px;
  }

  .button-container-1 {
    margin-top: 14px;
    margin-bottom: 29px;
    float: none;
    align-items: center;
  }

  .request-button-container{
    margin-top: 14px;
    margin-bottom: 29px;
    float: none;
    align-items: center;
  }

  .fill-product-content-container {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .fill-product-content-container-data {
    padding-bottom: 0px;
  }

  .preview-button-container {
    display: flex;
    justify-content: center;
  }

  .descrption-error-message {
    color: red;
    font-size: 12px;
    font-family: Poppins;
    display: flex;
    align-items: center;
  }
}