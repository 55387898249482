#header-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.mode-container {
  display: flex;
  flex-direction: row;
  margin-right: 25%;
}

.mode-button {
  width: 182;
  height: 60px;
  border-Radius: 40px;
  border-Color: #E5E4E3;
  border: 1px solid #E5E4E3;
  flex-shrink: 0;
  color: #FFFFFF;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  margin-left: 1%;
  padding-inline: 32px;
  cursor: pointer;
}

#seller-button {
  margin-left: 15px;
}

#buyer-button::before {
  content: "Buyer Page";
}

#seller-button::before {
  content: "Seller Page";
}

#logo {
  float: left;
  cursor: pointer;
}

#action-container {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 10%;
}

.notification-container {
  position: relative;
}

#notification {
  cursor: pointer;
}

.new-notifications-present {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #FD9340;
  position: absolute;
  margin-top: -105%;
  margin-left: 60%;
}

#favorite {
  padding-left: 3%;
  padding-right: 8%;
  cursor: pointer;
}

#profile {
  position: relative;
  cursor: pointer;
}

#feedback {
  position: relative;
  right: 5%;
  cursor: pointer;

  @media (max-width:768px) {
    display: none;
  }
}

.merchant-logo-container {
  width: 79px;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.merchantLogo {
  max-width: 79px;
  max-height: 79px;
  /* border-radius: 50%; */
}

#feedback-hidden {
  display: none;
}

@media (max-width: 1024px) {
  .mode-button {
    width: 182;
    height: 60px;
    border-Radius: 30px;
    font-size: 15px;
    font-weight: 700;
    padding-inline: 20px;
  }

  #favorite {
    padding-left: 2%;
    padding-right: 5%;
  }
}

@media (max-width:1024px) and (min-width:769px) {
  #feedback {
    right: 3%;
  }
}

@media (max-width: 768px) {
  .merchant-logo-container {
    width: 63px;
    height: 63px;
  }

  #header-container {
    margin-top: 10px;
  }

  #seller-button {
    margin-left: 10px;
  }

  #buyer-button::before {
    content: "Buyer";
  }

  #seller-button::before {
    content: "Seller";
  }

  .mode-button {
    width: 63px;
    font-size: 12px;
    height: 25px;
    padding-inline: 5px;
  }

  .merchantLogo {
    max-width: 63px;
    max-height: 63px;
  }

  .mode-container {
    /* display: flex; */
    flex-direction: row;
    float: right;
    margin-right: 0%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 3%;
  }

  #favorite {
    padding-left: 5%;
    padding-right: 10%;
  }

  #notification {
    padding-left: 0%;
    cursor: pointer;
  }

  #feedback-hidden {
    display: contents;
  }

  .hidden-style {
    position: relative;
    right: 7.2rem;
    top: 3.5rem;
    @media (max-width:768px) and (min-width:460px) {
      top: 4rem;
    }
  }

  .new-notifications-present {
    width: 6px;
    height: 6px;
  }
}