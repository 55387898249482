.dropdown-container {
  margin-top: 19px;
  margin-left: 17px;
}

.dropdown-parameter-title-text {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}


.dropdown-containers10 {
  margin-top: 5px;
  position: relative;
}

.containerdropdownparam-styles10 {
  width: 257.004px;
  height: 33.35px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  border: none;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-align: center;
  outline: none;
  justify-content: center;
}

.dropdownparam-options10 {
  width: 257.004px;
  flex-shrink: 0;
  border-radius: 6.301px;
  background: #E5E4E3;
  position: absolute;
  top: 53%;
  left: 0%;
  z-index: 1;
  height: 185.644px;
}

.dropdownparam-options10 div:hover .dropdown-text-style10 {
  background-color: #F8F8F8;
}

.dropdownparam-options10 div:hover .squaredrop10 {
  background-color: #FD9340;
  ;

}

.dropdownparam-options10 div:hover {
  background-color: #F8F8F8;
}

.squaredrop10 {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  position: absolute;
  margin-top: 12px;
  background-color: white;
}

.dropdown-text-style10 {
  padding-left: 30px;
  margin-top: -3px;
  padding-top: 15px;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}




  .rippledropdown10{
    position: relative;
    overflow: hidden;
    
    
  }




.dropdownparam-options10 {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  text-align: center;
 justify-content: center;
}

.dropdownparam-options10::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.dropdownparam-options10::-webkit-scrollbar-thumb {
  background-color: #FFA500;
  border-radius: 5px;
}

.dropdownparam-options10::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  width: 10px;
}

.parameterparam-text10 {
  color: #FFA500;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  padding-left: 5px;
}
