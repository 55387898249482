.verify-container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  flex-shrink: 0;
  background-color: gray;
  overflow: auto;
}

.verify-content {
  width: 80.74%;
  border-radius: 25px;
  background: #fff;
  display: flex;
  height: 75.44%;
  margin-left: 10.26%;
  margin-right: 6.71%;
  margin-top: 7.66%;
  margin-bottom: 1.88%;
  overflow: auto;
}

.vmScrap-image {
  width: 100%;
  height: 100%;
  margin: 0%;
  border-radius: 25px;
  filter: blur(0);
  color: inherit;
  background-color: transparent;
}

.verifyMaillogo-image {
  position: absolute;
  width: 210px;
  height: 105px;
  z-index: 1;
}

.veifyMailimage-container {
  width: 45%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-shrink: 0;
}

.form-container-verifyMail {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 0% 8%;
  overflow: auto;
}

.verifyForm {
  height: 100%;
  padding: 3% 0%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-height: 950px) {
    padding: 10% 0%;
  }
}

.verifyAccount {
  font-size: 30px;
  margin: 0;
  color: #000;
  margin-bottom: 10px;
  margin-top: 4%;
  font-family: "PoppinsSemiBold";
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  @media (min-height: 950px) {
    width: calc(66% + 30px);
    text-align: center;
  }
}

.verifyAccount-verifyMail {
  font-size: 30px;
  margin: 0;
  color: #000;
  margin-bottom: 10px;
  margin-top: 6%;
  font-family: "PoppinsSemiBold";
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  @media (min-height: 950px) {
    width: calc(66% + 30px);
    text-align: center;
  }
}

.otpInputContainer {
  display: flex;
  align-items: center;
  margin-top: 17%;
  width: calc(66% + 30px);
  gap: 15px;
}

.otpInput {
  width: 25%;
  height: auto;
  border: none;
  font-size: 18px;
  text-align: center;
  padding-right: -15% !important;
  border-radius: 0 !important;
  outline: none;
  background-color: transparent;
  padding: 5px 0;
  color: #d9d9d9;
  border-bottom: 3px solid #d9d9d9;
}

.otpInput::-webkit-outer-spin-button,
.otpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.messageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(66% + 30px);
}

.message {
  color: var(--greyscale-black-30, #b3b1b0);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 100%;
  margin-block-start: 0em;
  margin-top: 29.34px;
}

.error-message-container {
  color: #ff4d4f;
  font-family: "Poppins";
  font-size: 12px;
  margin: 5px 0px;
}

.verifyAccount-form-container {
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.continueButton {
  width: calc(66% + 30px);
  height: 50px;
  flex-shrink: 0;
  margin-top: 15px;
  font-family: "Poppins";
  background-color: #b3b1b0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 30.5px;
  cursor: auto;
}

.continueButton.active {
  background-color: #fd9340;
  cursor: pointer;
}

.resendOtptext-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(66% + 30px);
}

.resendOtptext {
  color: var(--greyscale-black-90, #1a1919);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.create-account-link {
  color: var(--main-color-100, #fd9340);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.have-accounttext-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6%;
  width: calc(66% + 30px);
}

.have-accounttext {
  font-size: 11px;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

.have-accountlink {
  color: #fd9340;
  text-decoration: none;
}

@media (max-width: 768px) {
  body {
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: white;
  }

  .verify-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-left: 0px;
    margin: 0%;
    overflow-y: auto;
    border-radius: 0px;
  }

  .verify-container {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    display: flex;
  }

  .verify-content::-webkit-scrollbar {
    width: 0px;
  }

  .veifyMailimage-container {
    width: 100%;
  }

  .vmScrap-image {
    object-fit: cover;
    height: 202px;
    width: 100%;
    margin-bottom: auto;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
  }

  .verifyMaillogo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 210px;
    height: 105px;
    z-index: 1;
  }

  .form-container-verifyMail {
    align-self: center;
    overflow: unset;
    margin: 0% 5%;
  }

  .verifyAccount {
    height: unset;
  }

  .verifyAccount-verifyMail {
    height: unset;
  }

  .resendOtptext-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0%;
    margin-top: 12%;
    width: 100%;
  }

  .messageContainer {
    width: 100%;
  }

  .continueButton {
    width: calc(90% + 30px);
    height: calc(36px + 30px);
  }

  .have-accounttext-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0%;
    width: 100%;
  }

  .have-accounttext {
    margin: 0;
    padding: 0;
  }

  .otpInputContainer {
    justify-content: center;
    margin-top: 20.32%;
    width: 100%;
  }

  .error-message-container {
    color: #ff4d4f;
    font-family: "Poppins";
    font-size: 14px;
    margin-top: 5px;
  }

  .verifyForm {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 0px;
  }

  .message {
    width: 100%;
    margin-top: 5%;
  }
}
