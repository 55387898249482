.product-page{
    overflow-y: scroll;
    height: 102vh;
  }

.product-detail-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    margin-top: 60px;
    max-width: fit-content;
    margin-right: 5px;
  }
  
  .product-detail-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .product-detail-container-header-title {
    color: #FD9340;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    margin-left: 20px;
  }
  
  .product-detail-container-body {
    margin-left: 56px;
  }
  
  .product-detail-container-main {
    display: flex;
    flex-direction: row;
  }
  
  .product-images-container {
    justify-content: space-between;
    align-items: center;
  }
  
  .product-features-container {
    margin-right: 10px;
    margin-left: 170px;
    width: 588px;
  }
  
  .product-feature-header {
    display: flex;
  }
  
  .product-feature-header-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
  }
  
  .product-feature-favorite {
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    cursor: pointer;
  }
  
  .product-feature-description {
    color: #B3B1B0;
    text-align: justify;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
    height: 130px;
    flex-shrink: 0;
    max-height: 85vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .product-feature-description-long {
    height: 203px;
  }
  
  .product-feature-description-extra-long {
    height: 279px;
  }

    .product-parameters {
    height: 100px;
    border-radius: 25px;
    background: #f8f8f8;
    padding-top: 25px;
    padding-left: 46px;
    max-height: 85vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .product-feature-parameters {
    height: 131px;
    border-radius: 25px;
    background: #f8f8f8;
    padding-top: 25px;
    padding-left: 46px;
    max-height: 85vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .product-feature-parameters-long {
    height: 209px;
  }
  
  .product-feature-parameter {
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }
  
  .product-feature-trade {
    display: flex;
    width: 100%;
    margin-top: 48px;
  }

  .product-feature-quantity-container {
    width: 100%;
  }
  
  .product-feature-quantity {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; 
  }
  

  .product-feature-price-container {
    width: 100%;
  }

  .product-feature-price {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    float: right;
  }

    .requirement-text {
    color: #FD9340;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    float: right;
  }
  
  .company-detail2 {
    color: var(--greyscale-black-30, #B3B1B0);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    width: 518px;
    height: 58px;
    flex-shrink: 0;
    margin-top: 5%;
  }
  
  .company-name2 {
    margin-left: 10%;
    height: 46px;
    flex-shrink: 0;
  }
  
  .company-name-text5 {
    color: #FD9340;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    margin-left: 20%;
  }
  
  .seller-name {
    color: var(--greyscale-black-100, #000);
    font-family: 'PoppinsBold';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    margin-left: 25%;
    margin-top: 1%;
  }

  .contact-button {
    display: inline-flex;
    align-items: center;
    border: none;
    padding: 10px 80px;
    gap: 10px;
    border-radius: 25px;
    background: var(--main-color-100, #FD9340);
    cursor: pointer;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-left: 57px;
    margin-bottom: 20px;
  }

  .contact-seller-container {
    display: flex;
    flex-direction: row;
  }
  
  .product-seller-company {
    display: flex;
  }
  
  .product-seller-company-title {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    padding-block: 14px;
    padding-right: 20px;
  }
  
  .product-seller-button {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    color: #FD9340;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    justify-self: center;
    padding-block: 14px;
    padding-inline: 9.6px;
    margin-right: 14.65px;
    cursor: pointer;
    width: fit-content;
  }
  
  .product-seller-button-text {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    white-space: nowrap;
  }
  
  .contact-seller-text10 {
    color: var(--Color-2, #1E1E1E);
    text-align: center;
    font-family: "PoppinsSemiBold";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  
  .company2 {
    width: 34.828px;
    height: 32.997px;
    flex-shrink: 0;
    flex: 1;
  }
  
  .text2 {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    margin-right: 70%;
    margin-top: 16px;
  }
  
  .product-merchant-activity {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    overflow: auto;
    padding: 10px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .product-activity-container-recent {
    margin-top: 81px;
  }
  
  .product-activity-container-inquired {
    margin: 35px 0;
  }
  
  .product-activity-container-text {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
  }
  
  .modal-background2 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content2 {
    background-color: white;
    justify-content: space-evenly;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32.5%;
    /* max-height: 85vh; */
    overflow-y: auto;
    padding: 20px;
    width: 48vw;
    margin-bottom: 33%;
  
    margin-left: 25%;
  }
  
  .modal-content2::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  
  .modal-content2 {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .modal-content2:-webkit-scrollbar {
    width: 0;
  }
  
  .modal-content2 {
    scrollbar-width: none;
  }
  
  .modal-content2 {
    -ms-overflow-style: none;
  }
  
  .modal-content2::-webkit-scrollbar-thumb {
    display: none;
  }
  
  .sliderContainer2 {
    display: none;
  }
  
  
  .modal2 {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .items2 {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  
  .blur {
    filter: blur(2px);
    background: rgba(0, 0, 0, 0.50);
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    pointer-events: none;
  
  }
  
  .element {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .parameterTitle2 {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  
  .parameterTitle4 {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 6%;
  }
  
  .parameterTitle5 {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  
  .parameterValue2 {
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  
  .align-end {
    display: flex;
    justify-content: flex-start;
    margin-left: 2%;
  }
  
  .align-end2 {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  
  .parametercontainer2 {
    width: 646.607px;
    height: 41.529px;
    flex-shrink: 0;
    border-radius: 5.511px;
    background: #FFF;
    margin: '5%';
    margin-left: 6%;
  }
  
  .contactmain-section2 {
    display: flex;
    margin-left: 5%;
  }
  
  .contact-section2 {
    flex: 1;
  }
  
  .contact-container2 {
    width: 490.323px;
    height: 41.529px;
    flex-shrink: 0;
    border-radius: 5.511px;
    background: #FFF;
    margin-left: 3.5%;
    margin-top: 2%;
  }
  
  .contact-container7 {
    width: 500.323px;
    height: 41.529px;
    flex-shrink: 0;
    border-radius: 5.511px;
    background: #FFF;
    margin-left: 11.5%;
    margin-top: 2%;
  }
  
  .parameterValue-description2 {
    width: 646.607px;
    height: 88.012px;
    flex-shrink: 0;
    border-radius: 5.511px;
    background: #FFF;
    margin-left: 6%;
  }
  
  .parameterValue-location2 {
    width: 646.607px;
    height: 126.563px;
    flex-shrink: 0;
    border-radius: 5.511px;
    background: #FFF;
    margin-left: 6%;
  }
  
  .parameterTitle2 {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: 6%;
    margin-top: -1%;
  }
  
  .parameterTitle3 {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-left: -75%;
  }
  
  .Email-container2 {
    width: 490.323px;
    height: 41.529px;
    flex-shrink: 0;
    border-radius: 5.511px;
    background: #FFF;
    margin-left: 13%;
  }
  
  .company-image2 {
    width: 651.017px;
    height: 433.906px;
    flex-shrink: 0;
  }
  
  .logo-image2 {
    width: 198.4px;
    height: 198.4px;
    flex-shrink: 0;
  }
  
  .parameterValue3 {
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 2%;
  }
  
  .parameterValue4 {
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 2%;
  }
  
  .contactmain-section9 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .contact-container9 {
    width: 490.323px;
    height: 41.529px;
    flex-shrink: 0;
    border-radius: 5.511px;
    background: #FFF;
  }
  
  
  @media (max-width: 1024px) {
    .product-detail-container {
      margin-left: 30px;
    }
    .product-detail-container-body {
      margin-left: 56px;
    }
    .product-features-container {
      margin-left: 50px;
    }
  }
  
@media (max-width: 768px) {
    .product-detail-container {
      margin-left: 0px;
      margin-top: 0px;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 100%;
    }
    .product-detail-container-body {
      margin-left: 0px;
    }
    .product-detail-container-header {
      display: flex;
      flex-direction: row;
    }
    .product-detail-container-main {
      flex-direction: column;
    }
    .product-detail-container-main-left {
      display: flex;
      flex-direction: row;
      margin-inline: 0%;
      margin-top: 0px;
      justify-content: center;
      align-self: center;
    }
    .product-detail-back {
      margin-left: 19px;
    }
    .product-detail-container-header-title{
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 75.02px;
      align-items: center;
      width: 100%;
      margin-left: 35px;
      margin-right: 50px;
    }
    .product-images-container {
      justify-content: center;
      align-items: center;
      width: 80%;
    }
    .product-feature-favorite {
      width: 100%;
      height: 33px;
      display: flex;
      justify-content: center;
      padding-left: 22px;
      cursor: pointer;
    }
    .product-features-container {
      margin-left: 7px;
      margin-right: 9px;
      width: 100%;
      display: contents;
    }
    .product-feature-header {
      margin-left: 19px;
    }
    .product-feature-header-title{
      font-size: 10.815px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
    }
    .product-feature-description {
      margin-top: 8px;
      margin-bottom: 5px;
      margin-left: 19px;
      margin-right: 11px;
      height: 100%;
      font-size: 10.815px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
    .product-parameters {
      height: 100%;
      border-radius: 16.898px;
      padding-block: 16px;
      padding-left: 11px;
      margin-right: 9px;
      margin-left: 7px;
    }
    .product-feature-parameters {
      height: 100%;
      border-radius: 16.898px;
      padding-block: 16px;
      padding-left: 11px;
      margin-right: 9px;
      margin-left: 7px;
    }
    .product-feature-parameter {
      font-size: 10.815px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
    .product-feature-trade {
      margin-top: 39px;
    }
    .product-feature-quantity {
      color: var(--greyscale-black-100, #000);
      font-family: Poppins;
      font-size: 13.519px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; 
      padding-left: 31px;
    }
    .product-feature-price-container {
      width: inherit;
    }
    .product-feature-price {
      color: var(--greyscale-black-100, #000);
      font-family: Poppins;
      font-size: 13.519px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 30px */
      padding-right: 31px;
    }  
    .contact-seller-container {
      display: flex;
      flex-direction: column;
    }
    .product-seller-company {
      display: flex;
      margin-top: 26px;
      margin-inline: 20px;
    }
    .product-seller-company-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
      padding-block: 14px;
      height: fit-content;
      white-space: nowrap;
    }
    .owner-seller-button-container{
      width: 100%;
      height: fit-content;
      display: flex;
      float: right;
      justify-content: flex-end
    }
    .contact-seller-text10 {
      color: var(--Color-2, #1E1E1E);
      text-align: center;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .product-activity-container-recent {
      margin-top: 14px;
      padding-left: 19px;
      margin-right: 30px;
    }
    .product-activity-container-inquired {
      margin-bottom: 94px;
      padding-left: 19px;
      margin-right: 30px;
    }
    .product-merchant-activity {
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      width: 100%;
    }
    .contact-button-container{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .contact-button {
      display: flex;
      padding: 8px 55px;
      font-size: 12px;
      margin-left: 0px;
      margin-bottom: 20px;
      align-items: center;
    } 
    .requirement-text {
      display: flex;
      font-size: 10px;
      font-weight: 600;
      line-height: 150%;
      margin-right: 6%;
      justify-content: flex-end;
    }
}