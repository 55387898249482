.root-footer-container {
  background-color: #FD9340;
  /* height: 100%; */
  margin-bottom: 0%;
}

.footer-container {
  background-color: #FD9340;
  flex-direction: row;
  display: flex;
  /* padding-top: 47px; */
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.contact-container {
  width: calc(100% / 3);
  /* align-content: center; */
  align-items: center;
  /* display: flex; */
}

.contact-information {
  display: flex;
  flex-direction: column;
  align-self: center;
  /* align-items: center; */
  gap: 24px;
  margin-left: 63px;
  /* width: 240px; */
  margin-top: 40px;
}

.dummy-container {
  width: calc(100% / 3);
}

.headingtext-style-a2 {
  color: var(--color-2, #1E1E1E);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.company-trademark {
  display: flex;
  align-self: center;
  align-content: center;
  flex-direction: column;
  width: calc(100% / 3);
  /* padding-right: 40%; */
}

.address-container {
  width: 250px;
  align-items: center;
}

.para-style-a2 {
  color: var(--black, #1A202C);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.footer-image-container-a2 {
  display: flex;
  /* justify-content: center; */
  align-self: center;
  /* padding-top: 20%; */
}

.info-style-a2 {
  width: 1440px;
  height: 250px;
  flex-shrink: 0;
}

.address-style-a2 {
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-style-a2 {
  color: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contactcontainer-style-a2 {
  height: 27px;
  width: 100%;
}

@media (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-image-container-a2 {
  }

  .footer-container {
    padding-top: 21px;
  }

  .contact-container {
    display: flex;
    width: 100%;
    gap: 24px;
    margin-top: 0px;
    padding-top: 0px;
    justify-content: center;
  }

  .headingtext-style-a2 {
    color: var(--color-2, #1E1E1E);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .contact-information {
    margin-left: 0px;
    margin-top: 15px;
    /* width: 200px; */
    /* width: 100%; */

  }

  .address-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .address-style-a2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    width: 200px;
  }

  .contact-style-a2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    /* width: 195px; */
  }

  .para-style-a2 {
    color: var(--black, #1A202C);
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 0px;
  }

  .footer-image-a2 {
    width: 128px;
  }
}