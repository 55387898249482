.imageLoaderContainer {
    width: 96px;
    height: 96px;
    background-color: #D9D9D9;
    margin-right: 6px;
}

.image-loading-container {
    width: 92px;
    height: 5px;
    flex-shrink: 0;
    border-radius: 25px;
    background: #FFF;
    margin-inline: 2px;
    margin-top: 88px
}

.image-upload-progress-bar {
    border-radius: 25px;
    background: #FD9340;
    height: 5px;
}

.cancel-load-container {
    width: 100%;
    display: flex;
    float: right;
    justify-content: flex-end;
}

.remove-icon {
    cursor: pointer;
}