.go-backContainer{
    display: flex;
    align-items: center;
    margin-left: 6%;
    margin-top: 20px;
}

.arrow-container{
}

.arrow-img{
    height: 30px;
    width: 30px;
}

.favorite-header{
    color: #000;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px; /* 200% */
    margin-left: 20px;
}

.drafts{
display: flex;
justify-content: center;
width: 100%;
margin-bottom: 15px;
flex-direction: column;
align-items: center;
}

.draft-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
    .favorite-header{
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 147%; /* 29.4px */
        margin-left: 22px;
    }
}