.signup-detail-entry-container {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.signup-detail-entry-actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  position: relative;

  @media (max-height: 680px) {
    margin-top: 10px;
  }
}

.enter-detailsandbackpress {
  cursor: pointer;
  position: absolute;
}

.createAccount-container {
  display: flex;
  flex-direction: column;
  min-width: 90%;
}

.enterdetailsandbackpress {
  margin-left: 25px;
}

.linkenlogout {
  width: 100%;
  padding-right: 25px;
}

#logout {
  padding: 14px 20px;
  gap: 10px;
  border-radius: 16px;
  background: var(--main-color-100, #fd9340);
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  display: flex;
  justify-content: flex-end;
  float: right;
  align-self: flex-end;
  align-items: flex-end;
  cursor: pointer;
}

.back {
  position: absolute;
  height: 10%;
  top: 18%;
  left: 16%;
}

.createAccount-content {
  margin: 0 auto;
  flex-shrink: 0;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
  overflow: hidden;
  margin-top: -62.5px;
  width: 80%;
  margin-inline: 80px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.signup-entry-logo-container {
  display: block;
  margin: auto;
  z-index: 2;

  @media (max-height: 680px) {
    margin-top: 0;
  }
}

.form-detail-text {
  font-size: 36px;
  padding-top: 0%;
  margin-bottom: 38px;
  width: 100%;
  font-family: "PoppinsSemiBold";
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  display: flex;
  justify-content: center;

  @media (max-height: 680px) {
    margin-bottom: 10px;
  }
}

.createAccount-content form {
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.getstarted-loading-sellerbuyer {
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 60px;
  max-height: 100%;
}

.aboutText {
  font-size: 20px;
  margin: 0;
  margin-top: 1%;
  font-family: "Poppins";
  font-style: normal;
}

.locationText {
  font-size: 20px;
  margin: 0;
  margin-top: 3%;
  font-family: "Poppins";
  font-style: normal;
}

.contactText {
  font-size: 20px;
  margin: 0;
  margin-top: 4%;
  font-family: "Poppins";
  font-style: normal;
}

.companyInput {
  padding: 15px;
  width: 50%;
  color: #b3b1b0;
  font-family: "Poppins";
  border: 1px solid #e5e4e3;
  border-radius: 10px;
  outline: none;
}

.contactInput {
  padding: 15px;
  width: 50%;
  font-family: "Poppins";
  color: #b3b1b0;
  border: 1px solid #e5e4e3;
  border-radius: 10px;
  outline: none;
}

.contactInput::-webkit-outer-spin-button,
.contactInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.about {
  display: grid;
  grid-template-columns: 30% auto;
}

.company {
  display: grid;
  grid-template-columns: 30% auto;
  align-items: center;
  margin-top: 5px;
}

.contact {
  display: grid;
  grid-template-columns: 30% auto;
  align-items: center;
  margin-top: 5px;
}

.aboutInput {
  min-height: 70px;
  font-family: "Poppins";
  padding: 5px 15px;
  color: #b3b1b0;
  border: 1px solid #e5e4e3;
  border-radius: 10px;
  outline: none;
  resize: none;
  overflow-y: auto;
}

.verifyybutton-wrapper {
  margin-bottom: 3%;
  display: grid;
  grid-template-columns: 30% auto;
}

.createAccount-content form .verifyyButton {
  width: calc(50% + 30px);
  height: 50px;
  padding: 15px;
  background-color: #b3b1b0;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 30.5px;
}

.createAccount-content form .verifyyButton.active {
  background-color: #fd9340;
  cursor: pointer;
}

.createAccount-content form .verifyyButton:not(:last-child) {
  margin-right: 10px;
}

.createAccount-content form.verifyyButton:hover {
  background-color: #fd9343;
}

.create-account-text {
  font-size: 11px;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  padding-top: 40px;
  padding-left: 15px;
}

.create-account-link {
  color: #fd9340;
  text-decoration: none;
}

.signUpPack {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.signUpPackContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttonContact {
  display: flex;
  flex-direction: column;
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.loading-dots {
  display: inline-block;
  margin: 0 5px;
  font-size: 20px;
}

.dot {
  animation: bounce 1s infinite alternate;
}

.signup-detail-entry-container {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1);
  /* Firefox */
}

.singup-entry-header {
  margin-top: 2%;
  position: relative;
}

.contact-rror-message-styles {
  color: red;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  height: 18px;
}

@media (max-width: 768px) {
  .signUpPackContainer {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
  }

  .signUpPack {
    width: 100%;
    margin-inline: 30px;
    gap: 40px;
  }

  .signup-detail-entry-actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0px;

    @media (max-height: 680px) {
      margin-top: 10px;
    }
  }

  .createAccount-content {
    margin: 0 auto;
    flex-shrink: 0;
    border-radius: 0px;
    background-color: #fff;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
    border: none;
    overflow: hidden;
    margin-top: 0px;
    width: 100%;
    height: 100%;
    margin-inline: 0px;
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    max-height: 100vh !important;
    justify-content: unset;
  }

  .createAccount-container {
    display: flex;
    flex-direction: column;
    min-width: auto;
    width: 100%;
  }

  .form-detail-text {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    align-items: center;
    margin-top: 5%;
  }

  .signup-detail-entry-actions {
    display: contents;
  }

  .singup-entry-header {
    display: flex;
  }

  .signup-entry-logo-container img {
    display: none;
  }

  .linkenlogout {
    display: none;
  }

  .createAccount-container {
    width: 100%;
    height: 100%;
    position: fixed;
    margin-top: 0px;
  }

  .about {
    grid-template-rows: 1fr auto;
    grid-template-columns: unset;
  }

  .company {
    grid-template-rows: 1fr auto;
    grid-template-columns: unset;
  }

  .contact {
    grid-template-rows: 1fr auto;
    grid-template-columns: unset;
  }

  .contactText {
    font-size: 20px;
    font-family: "Poppins";
    font-style: normal;
    display: flex;
    text-align: left;
    margin-left: 0%;
    margin-top: 0%;
  }

  .contactInput {
    min-width: 80%;
    padding: 20px;
    margin-top: 2%;
    margin-left: 0%;
    color: #b3b1b0;
    border: 1px solid #e5e4e3;
    border-radius: 10px;
    outline: none;
    width: auto;
  }

  .locationText {
    font-size: 20px;
    font-family: "Poppins";
    font-style: normal;
    display: flex;
    text-align: left;
    margin-left: 0%;
    margin-top: 0%;
  }

  .companyInput {
    width: auto;
    padding: 20px;
    margin-top: 2%;
    margin-left: 0%;
    color: #b3b1b0;
    border: 1px solid #e5e4e3;
    border-radius: 10px;
    outline: none;
  }

  .aboutText {
    font-size: 20px;
    font-family: "Poppins";
    font-style: normal;
    display: flex;
    text-align: left;
    margin-left: 0%;
    margin-top: 3%;
  }

  .aboutInput {
    width: auto;
    font-family: "Poppins";
    min-height: 70px;
    margin-top: 2%;
    margin-left: 0%;
    padding-left: 20px;
    color: #b3b1b0;
    border: 1px solid #e5e4e3;
    border-radius: 10px;
    outline: none;
    resize: none;
    overflow-y: auto;
    height: 93px;
  }

  .createAccount-content form h2 {
    font-family: "Poppins";
    font-style: normal;
    display: flex;
  }

  .aligninsingleline {
    display: flex;
    flex-direction: row;
  }

  .verifyybutton-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 30.5px;
    align-items: center;
  }

  .createAccount-content form {
    margin-top: -1%;
    background-color: transparent;
  }

  .back {
    position: relative;
    left: 11px;
    top: 33px;
  }
}
