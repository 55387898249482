.product-seller-button {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  color: #fd9340;
  /* flex-direction: center;  */
  border-radius: 16px;
  display: flex;
  justify-content: center;
  justify-self: center;
  padding-block: 14px;
  padding-inline: 9.6px;
  margin-right: 14.65px;
  cursor: pointer;
  width: fit-content;
}

.product-seller-button-text {
  /* Poppins/SemiBold/16 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 17.6px */
}

.product-feature-favorite {
  width: 100%;
  height: 33px;
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  cursor: pointer;
}

.action-container {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.product-feature-contact-seller-container {
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 12px 24px;
  gap: 10px;
  border-radius: 8px;
  background: #fd9340;
  margin-top: 0px;
}

.comment-section-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.comments-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24%;
}

.add-comment-button {
  background-color: #d9d9d95d;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #D9D9D9C8;
  height: 35px;
  min-width: 200px;
  gap: 10px;
  border-radius: 20px;
  font-family: "poppinsBold";
  color: #130f2694;
  cursor: pointer;
}

.view-all-comments-button {
  border: none;
  height: 25px;
  text-align: left;
  margin-top: 10px;
  color: #130f2684;
  font-family: "PoppinsBold";
  cursor: pointer;
  background-color: transparent;
}

.comment-icon {
  height: 25px;
  width: 25px;
}

.comments-header {
  border-bottom: 1px solid #b3b1b0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "poppinsSemiBold";
  padding-bottom: 20px;
  font-size: 17px;
  position: sticky;
  top: 0;
  background-color: white;
}

.comment-top-bar {
  height: 3px;
  background-color: #fd9340;
  width: 80px;
  border: none;
  margin: 20px 0 15px 0;
}

.comment-profile-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.comments-post-icon {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.comment-container {
  display: flex;
}

.comment-message-container {
  display: flex;
  flex-direction: column;
}

.comment-container {
  padding: 5px 20px;
  gap: 10px;
}

.commenter-name-time {
  display: flex;
  gap: 5px;
  align-items: center;
}

.comment-date {
  font-family: "PoppinsBold";
  color: #8f8f8f;
  border: none;
  text-align: center;
  margin: 5px 0;
  font-size: 15px;
}

.comment-time {
  font-size: 14px;
  color: #8f8f8f;
  font-family: "Poppins";
  margin: 0;
  min-width: 68px;
}

.commenter-name {
  font-family: "PoppinsSemiBold";
  margin: 0;
  font-size: 17px;
}

.comment {
  font-family: "Poppins";
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
  line-height: 15px;
}

.reply-button {
  color: #50555c;
  font-family: "poppinsSemiBold";
  margin-top: 5px;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}

.post-comment {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.comment-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 15px;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
}

.comment-input {
  width: 70%;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 5px;
  background-color: transparent;
  height: 25px;
}

.comment-input:focus {
  border-color: #a9a9a9;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  outline: none;
}

.contact-seller-container {
  display: flex;
  flex-direction: row;
}

.comments-section {
  flex: 1;
  overflow-y: auto;
}

.reply-wrapper {
  display: flex;
  gap: 10px;
  padding-top: 7px;
}

.comments-reply-container {
  display: flex;
  flex-direction: column;
}

.reply-name-time {
  display: flex;
  gap: 5px;
}

.reply-profile-pic {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

.reply-username {
  font-family: "PoppinsSemiBold";
  font-size: 14px;
}

.reply-time {
  font-family: "PoppinsSemiBold";
  font-size: 11px;
  color: #8f8f8f;
  margin-top: 2px;
  min-width: 55px;
}

.comment-reply {
  font-family: "Poppins";
  font-size: 12px;
}

.reply-loading {
  color: #50555c;
  width: 120px;
  font-family: "PoppinsSemiBold";
  font-size: 12px;
  margin-top: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
}

.view-replies-button {
  color: #50555c;
  width: 120px;
  font-family: "PoppinsSemiBold";
  font-size: 12px;
  margin-top: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
}

.replieng-highlighter {
  font-family: "PoppinsSemiBold";
  font-size: 12px;
  background-color: #d9d9d9;
  padding: 3px 2px;
  color: #50555cd4;
  text-align: center;
}

.comment-submit-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}

.load-more-comments-button {
  color: #50555c;
  width: 180px;
  font-family: "PoppinsSemiBold";
  font-size: 14px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  margin: 8px 10%;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.loader-container {
  width: 18px;
  height: 18px;
  border: 6px solid #fff;
  border-top: 6px solid #fd9340;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.loader {
  display: flex;
  justify-content: center;
  height: 30px;
  align-items: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.product-seller-company {
  display: flex;
}

.product-seller-company-title {
  color: var(--greyscale-black-100, #000);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  padding-block: 14px;
  padding-right: 20px;
}

.contact-seller-text10 {
  color: var(--Color-2, #1e1e1e);
  text-align: center;
  font-family: "PoppinsSemiBold";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 768px) {
  .product-feature-favorite {
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: center;
    padding-left: 22px;
    cursor: pointer;
  }
  .contact-seller-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
  }
  .product-seller-company {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    margin-top: 26px;
    margin-inline: 20px;
  }
  .product-seller-company-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    padding-block: 14px;
    height: fit-content;
    white-space: nowrap;
    /* padding-right: 52px; */
  }
  .product-feature-contact-seller-container {
    display: inline-flex;
    padding: 14px 20px;
    display: flex;
    align-self: flex-end;
    justify-content: center;
    gap: 10px;
    border-radius: 16px;
    margin-top: 14px;
    margin-right: 11px;
  }
  .owner-seller-button-container {
    width: 100%;
    height: fit-content;
    display: flex;
    /* align-items: e; */
    float: right;
    justify-content: flex-end;
  }
  .contact-seller-text10 {
    color: var(--Color-2, #1e1e1e);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .comments-wrapper {
    margin: 14px 0 0 11px;
  }

  .add-comment-button {
    height: 30px;
    min-width: 165px;
    gap: 5px;
    border-radius: 20px;
    font-size: 12px;
  }

  .view-all-comments-button {
    height: 20px;
    margin-top: 4px;
  }

  .comment-icon {
    height: 20px;
    width: 20px;
  }
  .comments-header {
    padding-bottom: 15px;
    font-size: 14px;
  }

  .comment-top-bar {
    height: 2px;
    margin: 16px 0 10px 0;
  }

  .comment-profile-icon {
    height: 30px;
    width: 30px;
  }

  .comments-post-icon {
    height: 25px;
    width: 25px;
  }

  .comment-container {
    padding: 5px 10px;
    gap: 7px;
  }

  .commenter-name-time {
    gap: 3px;
  }

  .comment-date {
    margin: 3px 0;
    font-size: 13px;
  }

  .comment-time {
    font-size: 10px;
    min-width: 48px;
  }

  .commenter-name {
    font-size: 13px;
  }

  .comment {
    font-size: 10px;
    margin-top: 3px;
    line-height: 12px;
  }

  .reply-button {
    margin-top: 3px;
  }

  .comment-input-container {
    padding: 7px 15px;
    gap: 8px;
  }

  .comment-input {
    padding: 5px 10px;
    height: 20px;
    font-size: 11px;
  }

  .reply-wrapper {
    gap: 7px;
  }

  .reply-name-time {
    gap: 3px;
  }

  .reply-profile-pic {
    height: 20px;
    width: 20px;
  }

  .reply-username {
    font-size: 11px;
  }

  .reply-time {
    font-size: 8px;
    margin-top: 1px;
    min-width: 40px;
  }

  .comment-reply {
    font-size: 10px;
  }

  .reply-loading {
    font-size: 9px;
    margin-top: 3px;
  }

  .view-replies-button {
    font-size: 9px;
    margin-top: 3px;
  }

  .replieng-highlighter {
    font-size: 9px;
    padding: 2px 1px;
  }

  .loader-container {
    width: 14px;
    height: 14px;
    border: 4px solid #fff;
    border-top: 4px solid #fd9340;
  }

  .load-more-comments-button {
    width: 140px;
    font-size: 11px;
    margin: 5px 10%;
  }
}
