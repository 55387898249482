.awesome-slider .awssld__bullets .awssld__bullets--active {

    background-color: #fd9340;
     /* Active color: Orange */
}

  .awesome-slider .awssld__bullets .awssld__bullets--normal {
    background-color:#D9D9D9; /* Normal color: Gray */
  }

