.maintitle-style {
  color: #000;
  font-family: PoppinsBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  width: 97px;
  height: 36px;
  margin: auto;
  justify-content: center;
  align-items: center;
  /* margin-top: 19px; */
}

.add-product-header-text-container {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 50px;
}

.add-product-header {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 4px;
}

.add-product-back-press {
  /* position: fixed; */
}

.modal-divstyle {
  max-height: 95vh;
  overflow: auto;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for Internet Explorer and Edge */
  z-index: 99;
}

.modal-divstyle::-webkit-scrollbar {
  width: 0;
  /* Hide scrollbar for WebKit browsers (Chrome, Safari, etc.) */
}

.modalone-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.textcontainer-style {
  width: '50%';
  height: 0px;
  flex-shrink: 0;
  margin-top: -10px;
}

.text-style {
  color: var(--greyscale-black-30, #B3B1B0);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.maincontainer-style {
  position: relative;
  margin-bottom: 35px;
}

.category-button {
  display: flex;
  width: 300px;
  height: 100%;
  padding: 14px 20px;
  align-items: flex-start;
  color: #000;
  border-radius: 16px;
  background: var(--greyscale-black-background, #F8F8F8);
  border: 1px solid #FD9340;
  text-decoration: none;
  margin: auto;
  margin-top: 38px;
  font-family: PoppinsSemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  cursor: pointer;
}

.category-list-container {
  margin-bottom: 275px;
}

.helpButton{
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.helpText {
  color: #FD9340;
  text-align: right;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  /* 21.6px */
}