.image-view-container {
    width: 96px;
    height: 96px;
    background-color: #D9D9D9;
    position: relative;
    margin-right: 6px;
}

.image-remove-container {
    width: 100%;
    display: flex;
    float: right;
    justify-content: flex-end;
    position: absolute;
}

.image-view-image {
    width: fit-content;
    height: fit-content;
    max-height: 96px;
    max-width: 96px;
}

.image-file-container {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove-icon {
    cursor: pointer;
}