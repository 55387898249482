.seller-profile-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 193px
}

.addlogo-text-style::before {
  content: "Add Logo";
}

.addlogo-text-style {
  color: #FFF;
  font-family: PoppinsBold;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seller-logo-container {
  margin-left: 3%;
  margin-right: 3%;
}

.renderLogo-style {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seller-profile-container-content {
  background-color: white;
  max-height: fit-content;
  max-width: 682px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 25px;
  margin-top: 55px;
}

.seller-profile-container-content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.profile-background-image-container {
  position: relative;
  width: 651.017px;
  height: 433.906px;
  overflow: hidden;
}

.profile-background-image {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 37px;
  image-rendering: pixelated;
}

.profile-images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  padding-block: 10px;
}

.logo-image {
  max-width: 200px;
  max-height: 200px;
}

.edit-background-image-icon {
  position: absolute;
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  cursor: pointer;
  /* Show pointer cursor for interaction */
}

.edit-background-image {
  margin-left: 88%;
  margin-right: auto;
  display: flex;
  justify-content: center;
  margin-top: -310px;
}

.background-image-container-profilepage {
  justify-content: center;
  align-items: center;
  width: 651.017px;
  height: 320px;
  margin-left: -2px;
  margin-right: auto;
}

.profile-image-container-profilepage {
  height: 100%;
  width: 44%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-left: 34%;
  margin-top: -14%;
}

.profile-banner-logo-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FD9340;
  max-height: 25vh;
  border-radius: 50%;
  max-width: 99%;
}

.profile-image-container {
  width: 198.904px;
  height: 198.904px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F0F0F0;
  margin-left: 35%;
  margin-top: -6%;
}

.profile-banner-logo {
  color: #FFF;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.seller-logo-image {
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin-top: -100px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.edit-profile-image {
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  position: relative;
}

.company-detail-container {
  padding-inline: 45px;
  background: #F8F8F8;
  padding-bottom: 50px;
  margin-top: 20.3px;
}

.company-parameter-container {
  margin-bottom: 16.6px;
}

::-webkit-scrollbar {
  /* display: none; */
}

.profile-update-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.company-contact-parameter-container {
  display: flex;
  padding-bottom: 9.6px;
}

.parameter-name {
  color: var(--greyscale-black-100, #000);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.parameter-name-contact {
  min-width: 169px;
}

.parameter-value-container {
  border-radius: 5.511px;
  background: #FFF;
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  padding: 8px;
}

.parameter-contact-value-container {
  width: 170%;
  overflow: auto;
  white-space: normal;
  word-wrap: break-word;
}

#parameter-descrption-container {
  height: 88px;
  overflow: auto;
  white-space: normal;
  word-wrap: break-word;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

#parameter-descrption-container::-webkit-scrollbar {
  width: 0px;
}

#parameter-descrption-container::-webkit-scrollbar-thumb {
  background-color: #888;
}

#parameter-descrption-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#parameter-address-value-container {
  height: 98px;
  overflow: auto;
  white-space: normal;
  word-wrap: break-word;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

#parameter-address-value-container::-webkit-scrollbar {
  display: none;
}

#parameter-company-name-container {
  min-height: 30px;
  max-height: auto;
  white-space: normal;
  word-wrap: break-word;
}

.modal-background-a1 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.unclickable {
  pointer-events: none;
}

.modal-content-a1 {
  background-color: #F8F8F8;
  width: 744px;
  max-height: 90%;
  overflow: scroll;
  justify-content: space-evenly;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  font-family: Poppins;
}

.main-container-a1 {
  margin-left: 50px;
}

.infoTitle-a1 {
  width: 245.884px;
  height: 35.712px;
  flex-shrink: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 10px;
}

.profile-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.infoText-a1 {
  color: #B3B1B0;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border: none;
  padding-left: 10px;
  resize: none;
  border-radius: 5.51px;
}

#contact-no-title {
  width: 169.043px;
}

#email-title {
  width: 169.043px;
}

#company-name-value {
  background-color: '#fff';
  width: 93%;
  border-radius: 3;
  height: 41.53px;
}

#about-company-value {
  background-color: #fff;
  width: 93%;
  height: 88.01px;
}

#location-value {
  background-color: #fff;
  width: 93%;
  height: 126.56px;
}

#contact-no-value {
  background-color: #fff;
  width: 494.32px;
  height: 41.53px;
  margin-right: 36px;
}

#email-value {
  background-color: #fff;
  width: 494.32px;
  height: 41.53px;
  margin-right: 36px;
  pointer-events: none;
}

.newrow-container-a1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
}

.save-button-a1 {
  width: 327px;
  height: 60px;
  border: none;
  border-Radius: 30.5px;
  background-color: #FD9340;
  margin-top: 50px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save-button-a1:hover {
  background-color: #ff8b32;
}

#button-text::before {
  content: "Save Details";
}

.infoTitleSave-a1 {
  color: var(--greyscale-black-white, #FFF);
  text-align: center;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  align-items: center;
  justify-content: center;
}

.svg-button-a1 {
  cursor: pointer;
  height: 35.49px;
  width: 31.167px;
  margin-left: 100%;
  margin-top: 2%;
  border-color: transparent;
  border-style: none;
  background: none;
}

@media (max-width: 768px) {
  body {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .seller-profile-container-content {
    height: 100%;
    width: 100%;
    top: 0;
    border-radius: 0%;
    min-width: 200px;
    max-width: 768px;
    min-height: 300px;
    max-height: fit-content;
    margin-bottom: auto;
    margin-left: 0%;
    overflow-y: auto;
    margin-top: 5px;
  }

  .profile-background-image-container {
    position: relative;
    width: 355.227px;
    height: 245px;
    overflow: hidden;
  }

  .profile-background-image {
    border-radius: 25px;
    width: 100%;
    height: 100%;
    background-position: center;
  }

  .logo-image {
    max-width: 113px;
    max-height: 113px;
  }

  .seller-logo-image {
    width: 113px;
    height: 113px;
    margin-top: -56.5px;
    background: white;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .company-detail-container {
    margin-left: 3%;
    margin-right: 3%;
    padding-inline: 15px;
    background: #F8F8F8;
    padding-bottom: 50px;
  }

  .parameter-contact-value-container {
    width: 97%;
  }

  .addlogo-text-style::before {
    content: "Logo";
  }

  .addlogo-text-style {
    color: #FFF;
    font-family: PoppinsBold;
    font-size: 18.129px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: -8%;
  }

  .parameter-name {
    font-size: 20px;
  }

  .svg-button-a1 {
    margin-left: 95%;
  }

  .company-contact-parameter-container {
    flex-direction: column;
  }

  .renderLogo-style {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .edit-profile-image {
    margin-top: -37px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .edit-background-image {
    margin-left: auto;
    margin-right: 0.5%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (min-width: 701px)and (max-width:769px) {
  .profile-update-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.loading {
  pointer-events: none;
}

.save-button-a1 img {
  width: 30%;
  padding: 0px 20px;
  padding-top: 0px;
  position: relative;
  bottom: 15px;
}

.updateError {
  color: #ff4d4f;
  font-size: 14px;
  margin: 0;
  text-align: center;
  font-family: "Poppins";
}

@media (max-width:814px) {
  .modal-content-a1 {
    width: 80%;
    border-radius: 25px;
    min-width: 250.254px;
  }

  .save-button-a1 img {
    width: 20px;
    bottom: 0px;
  }

  .main-container-a1 {
    margin-left: 20px;
  }

  .infoTitle-a1 {
    font-size: 10px;
    height: 18px;
    line-height: 150%;
  }

  .infoText-a1 {
    font-size: 10px;
  }

  #company-name-title {
    width: 123.934px;
  }

  #company-name-value {
    height: 20.932px;
  }

  #about-company-title {
    width: 136.68px;
  }

  #about-company-value {
    height: 63.792px;
  }

  #location-title {
    width: 81px;
  }

  #location-value {
    height: 63.792px;
  }

  #contact-no-title {
    width: 85.203px;
  }

  #contact-no-value {
    width: 80%;
    min-width: 126.254px;
    height: 20.932px;
  }

  #email-title {
    width: 85.203px;
  }

  #email-value {
    width: 80%;
    min-width: 130px;
    height: 20.932px;
  }

  .newrow-container-a1 {
    margin-top: 0px;
  }

  #button-text::before {
    content: "Save";
  }

  .infoTitleSave-a1 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  .save-button-a1 {
    max-width: 70px;
    max-height: 50px;
    border-radius: 16px;
    display: inline-flex;
    padding: 14px 20px;
    align-items: flex-start;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 25px;
  }

  .updateError {
    font-size: 10px;
  }
}