.heroSection {
  height: 400px;
  width: 100%;
  background-image: url("../images/buyerPageImage.png");
  background-size: cover;
}

.herocontenttContaienr {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.heroContentt {
  width: 45%;
  border-radius: 25px;
  text-align: center;
}

.heroContentTxt {
  color: #fff;
  font-family: "PoppinsSemiBold";
  font-size: 48px;
  line-height: 64px;
}

.request-item-button {
  padding: 5px 40px 5px 40px;
  margin: 0;
  color: white;
  font-family: "PoppinsSemiBold";
  font-size: 35px;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid #fd9340;
  border-radius: 35px;
}

.request-item-button:hover {
  padding: 10px 50px 10px 50px;
  font-size: 37px;
  border-radius: 40px;
}

.itemsHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.itemsHeader h1 {
  padding: 0;
  margin: 0;
  margin-top: 13px;
  color: #fd9340;
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 700;
}

.itemsHeader h6 {
  padding: 0;
  margin: 0;
  margin-top: 7px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-page-feedback-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 2%;
}

.home-page-feedback-button {
  background-color: #fd9340;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 2px solid #fd9340;
  padding: 10px;
  color: #d9d9d9;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 0px; 
}

.buyerCardsContainer {
  padding-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.buyerCards {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  margin-left: 3%;
}

.buyerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 348px;
  width: 300px;
  border-radius: 15px;
  margin-bottom: 84px;
  margin-left: 1%;
  margin-right: 1%;
  cursor: pointer;
}

.buyerCardImage {
  height: 300px;
  width: 300px;
  object-fit: cover;
  border-radius: 15px;
}

.buyer-card-title {
  margin: 0;
  padding: 5px;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .heroContentTxt {
    color: white;
    font-family: "Poppins";
    font-size: 17.7px;
    line-height: 23.6px;
  }
  .heroContentt {
    width: 100%;
    border-radius: 9.2px;
    margin-inline: 5%;
    padding-left: 28px;
    padding-top: 14px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    padding-right: 25px;
    padding-bottom: 15px;
    height: fit-content;
    align-items: center;
    justify-content: center;
  }
  .request-item-button {
    padding: 5px 40px 5px 40px;
    margin: 0;
    color: white;
    font-family: "PoppinsSemiBold";
    font-size: 14px;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #fd9340;
    border-radius: 35px;
    width: fit-content;
  }

  .home-page-feedback-button {
    background-color: #fd9340;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 2px solid #fd9340;
    padding: 5px;
    color: #d9d9d9;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    cursor: pointer;
  }

  .home-page-fedback-icon {
    width: 25%;
    height: 25%;
  }

  .request-item-button:hover {
    padding: 10px 50px 10px 50px;
    font-size: 16px;
    border-radius: 40px;
  }

  .heroSection {
    height: 224px;
    width: 100%;
    background-image: url("../images/buyerPageImage.png");
    background-size: cover;
    margin: 0;
  }
  .itemsHeader {
    margin-top: 13px;
  }
  .itemsHeader h1 {
    margin-top: 0px;
    font-size: 24px;
  }
  .itemsHeader h6 {
    padding-top: 12px;
    font-size: 16px;
  }
  .buyerCards {
    margin-top: 0px;
  }
  .buyerCard {
    height: 147px;
    width: 114px;
    margin-bottom: 13px;
  }
  .buyerCardImage {
    height: 114px;
    width: 114px;
    object-fit: cover;
    border-radius: 12px;
  }
  .buyer-card-title {
    font-size: 14px;
  }
}
