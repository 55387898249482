.filter-page-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 100%;
  /* justify-content: center; */
  width: 100%;
}
.FilterpageLoadingSpinner{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.filter-tab-container {
  background: #EEE;
  width: 347px;
  flex-shrink: 0;
  float: left;
}

.no-items {
  font-family: Poppins;
  color: #b3b1b0;
  padding-top: 20vh;
}

.filter-header {
  display: flex;
  flex-direction: row;
  background-color: #FFF;
  padding-block: 10px;
  margin-right: 1px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.filter-text {
  color: var(--Color-2, #1E1E1E);
  font-family:"Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.clear-filter-container{
  width: 150px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 30.5px;
  border: none;
  background: var(--greyscale-black-10, #E5E4E3);
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 90px;
}

.clear-filter-text{
  color: var(--greyscale-black-white, #FFF);
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%; /* 28.8px */
}

.filter-text-container {
  width: 83px;
  height: 48px;
  flex-shrink: 0;
  background: #FFF;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination10 {
  display: flex;
  justify-content: center;

  margin-top: 59px;
  font-family: poppins;
  color: var(--greyscale-black-30, #B3B1B0);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 0px;
  /* margin-block: 0px; */
  padding-inline-start: 0px;
  align-items: flex-start;
  justify-content: left;
}

.pagination10 > li {
  margin: 0 5px;
  display: inline;
  font-size: 24px;
  color: #B3B1B0; /* Gray color for inactive page numbers */
  cursor: pointer;
  text-align: start;
}

.pagination10 > li.active {
  color: orange; /* Orange color for active page number */
}

.category-container {
  width: 100%;
  height: 47px;
  background: #000;

}

.category-text {
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-left: 20px;
  margin-right: 30px;
}

.container-input {
  border-radius: 5px;
  background: #FFF;
  outline: none;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 64.52px;
  height: 19px;
  flex-shrink: 0;
}

.container-dropdown-input {
  border-radius: 5px;
  background: #FFF;
  outline: none;
  border: none;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 257.004px;
  height: 51.99px;
  flex-shrink: 0;
}

.filter-button-container {
  display: flex;
  justify-content: right;
  width: 100%;
}

.filter-button {
  display: inline-flex;
  padding: 14px 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  border-radius: 16px;
  background: var(--main-color-100, #FD9340);
  cursor: pointer;
  margin-right: 14px;
  margin-top: 11px;
  margin-bottom: 38px;
}

.filter-items-category {
  color: #FD9340;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 64px;
  text-align: start;
  margin-left: 32px;
  margin-bottom: 38px;
}

.filter-items-content {
  margin-left: 124px;
  margin-bottom: 58px;
  width: 100%;
}

.apply-button {
  width: 327px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 30.5px;
  background: var(--main-color-100, #FD9340);
  margin-left: 10px;
  margin-top: 40px;
  margin-right: 30.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  color: var(--greyscale-black-white, #FFF);

  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  border: none;
  flex: auto;
  cursor: pointer;
  margin-bottom: 100px;
}

.apply-button-container img, .clearButton-container img{
  height: 80px;
}

.disableButton {
  opacity : 0.5;
  pointer-events: none;
}

.filter-result-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
}

.filter-pagination {
  display: flex;
  justify-content: center;
}

.filter-item-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 1400px) {
  .filter-items-content {
    margin-left: 65px;
  }
}

@media (max-width:1024px) {
  .pagination10 {
  }
}

@media (max-width:768px) {
  .filter-items-content {
    margin-left: 0px;
  }
  .filter-tab-container {
    width: 100%;
    background-color: #F8F8F8;
    border-radius: 25px;
  }
  .filter-header {
    padding-top: 25px;
    padding-bottom: 18px;
    margin-right: 0px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .filter-text {
    font-size: 24px;
  }
  .clear-filter-container{
    width: 100px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 15.5px;
    background: var(--greyscale-black-10, #E5E4E3);
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 70px;
  }
  .apply-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .apply-button {
    margin-inline: 15px;
    margin-bottom: 20px;
  }
  .filter-modal {
    margin-top: "69px";
    margin: 0;
    border-radius: "25px";
  }
}
