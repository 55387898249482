.buyer-profile-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.seller-profile-backpress {
  position: absolute;
  left: 3%;
  z-index: 2;
  top: 4%;
}

.buyer-profile-container-content {
  background-color: white;
  height: 100%;
  width: 100%;
  top: 0;
  border-radius: 0%;
  min-width: 200px;
  max-width: 768px;
  min-height: 300px;
  max-height: fit-content;
  margin-bottom: auto;
  margin-left: 0%;
  overflow-y: auto;
  border-radius: 37px;
  position: relative;
}

#seller-profile-company-name-container {
  min-height: 30px;
  max-height: auto;
  white-space: normal;
  word-wrap: break-word;
}

#seller-profile-description-container {
  height: 88px;
  overflow: auto;
  white-space: normal;
  word-wrap: break-word;
}

#seller-profile-addres-container {
  height: 88px;
  overflow: auto;
  white-space: normal;
  word-wrap: break-word;
}

.buyer-profile-container-content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.seller-background-image-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 11%;
  margin-top: 3%;
}

.buyer-background-image {
  height: 250px;
  max-width: 70%;
  width: 100%;
  image-rendering: pixelated;
  margin-inline: 4%;
  border-radius: 37px;
}

.buyer-background-image-logo {
  width: 100%;
  height: 100%;
  border-radius: 37px;
}

.buyer-logo-image-container {
  width: 136px;
  height: 136px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: absolute;
  bottom: 0%;
  top: 70%;
}

.buyer-logo-image {
  max-width: 100%;
  max-height: 100%;
}

.merchent-logo-buyer {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-top: -108px;
}

.company-detail-container {
  padding-inline: 45px;
  background: #f8f8f8;
  padding-bottom: 63px;
}

.seller-company-detail-container {
  padding-inline: 45px;
  background: #f8f8f8;
  padding-bottom: 20px;
  padding-top: 10px;
}

.company-parameter-container {
  margin-bottom: 16.6px;
}

.company-contact-parameter-container {
  display: flex;
  padding-bottom: 9.6px;
}

.parameter-name-text {
  color: var(--greyscale-black-100, #000);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.parameter-name-contact {
  min-width: 169px;
}

.parameter-value-text {
  border-radius: 5.511px;
  background: #fff;
  color: #b3b1b0;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  padding: 8px;
}

@media (max-width: 770px) {
  .seller-background-image-container {
    margin-bottom: 4rem;
  }

  .buyer-logo-image-container {
    width: 112.4px;
    height: 112.4px;
    top: 76%;
  }

  .buyer-logo-image {
    max-width: 112.4px;
    max-height: 112.4px;
  }

  .merchent-logo-buyer {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    margin-top: -60px;
  }

  .parameter-name-text {
    font-size: 14px;
  }

  .parameter-value-text {
    font-size: 12px;
    line-height: 100%;
    padding: 6px;
  }

  #seller-profile-company-name-container {
    min-height: 10px;
  }

  #seller-profile-description-container {
    height: 68px;
  }

  #seller-profile-addres-container {
    height: 68px;
  }
}
