.feedback-modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-container {
  width: 744px;
  height: 542.747px;
  background: #f8f8f8;
  margin-top: 70px;
  margin-bottom: 70px;
}

.feedback-button-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.feedback-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  width: 100%;
}

.feedback-backpress-button {
  display: none;
  visibility: hidden;
}

.feedback-heading {
  color: var(--Color-2, #1e1e1e);
  text-align: center;
  font-family: PoppinsBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.feedback-content {
  margin-left: 6%;
  margin-right: 6%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.feedback-content-text {
  color: #b3b1b0;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  /* 32px */
  margin-bottom: 2%;
}

.feedback-input {
  width: 92%;
  height: 195.677px;
  flex-shrink: 0;
  border-radius: 5.511px;
  background: #fff;
  border: none;
  outline: none;
  margin-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #b3b1b0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  white-space: normal;
  word-wrap: break-word;
  resize: none;
}

.feedback-input-limit {
  color: #b3b1b0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  margin-bottom: 5%;
}

.feedback-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.feedback-button {
  width: 327px;
  height: 60px;
  border: none;
  border-radius: 30.5px;
  background-color: white;
  margin-top: 7px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--greyscale-black-white, #fd9340);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  position: relative; 
  overflow: hidden;
}

.feedback-button-active {
    width: 327px;
    height: 60px;
    border: none;
    border-radius: 30.5px;
    background-color: #fd9340;
    margin-top: 7px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--greyscale-black-white, #fff);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    position: relative; 
    overflow: hidden;
  }

@media (max-width: 768px) {
  .feedback-container {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .feedback-heading{
    width: 80%;
    text-align: center;
    margin-right: 20%;
  }

  .feedback-backpress-button {
    display: flex;
    visibility: visible;
    width: 20%;
    display: flex;
    align-items: flex-start;
    padding-left: 5%;
  }

  .feedback-button {
    width: 200px;
    height: 50px;
  }

  .feedback-button-active {
    width: 200px;
    height: 50px;
  }

  .feedback-content-text {
    font-size: 14px;
  }

  .feedback-input-limit {
    font-size: 12px;
  }

  .feedback-input {
    font-size: 12px;
  }
}
