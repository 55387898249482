.entry-container {
  /* margin-block: 21px; */
  margin-left: 17px;
  margin-top: 9px;
}

.entry-header {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

.entry-value-container {
  display: flex;
  padding-top: 7px;
}

.value-text{
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  flex-shrink: 0;
}

.entry-container-input {
  border-radius: 5px;
  background: #FFF;
  outline:none;
  border:none;
  outline: none;
  width: 64.52px;
  height: 19px;
  flex-shrink: 0;
  margin-left: 15px;
}

.entry-filter-content {
  display: flex;
  align-items: center; 
}
.entry-filter-name {
  flex: 0 0 3%;
  margin-right: 10px; 
}
.entry-filter-input {
  display: flex; 
  align-items: center; 
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-family: Poppins;
}