.requested-item-page {
    width: 100vw;
    min-height: 88vh;
    position: relative;
}

.loading-spinner-container {
    padding-top: 14%;
}

.requested-item-detail-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    margin-top: 60px;
    max-width: fit-content;
    margin-right: 5px;
}

.requested-item-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.requested-item-category-title {
    color: #FD9340;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 64px;
    margin-left: 20px;
}

.requested-item-detail-container-body {
    margin-left: 56px;
}

.image-and-location-container{
    display: flex;
    flex-direction: column;
    position: relative;
}

.requested-item-detail-container-main {
    display: flex;
}

.merchant-location-container{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}

.requested-item-feature-container {
    margin-right: 10px;
    margin-left: 170px;
    width: 588px;
}

.requested-item-feature-header {
    display: flex;
}

.requested-item-feature-header-title {
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
}

.requested-item-feature-trade {
    display: flex;
    width: 100%;
    margin-top: 48px;
}

.requested-item-feature-quantity-container {
    width: 100%;
}

.requested-item-feature-quantity {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.requested-item-feature-price-container {
    width: 100%;
}

.requested-item-feature-price {
    color: var(--greyscale-black-100, #000);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    float: right;
}

.requested-item-feature-parameters {
    height: 131px;
    border-radius: 25px;
    background: #f8f8f8;
    padding-top: 25px;
    padding-left: 46px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: 1.3px solid #000000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.requested-item-feature-parameter {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}

.requested-item-feature-description {
    color: #060505;
    text-align: justify;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    height: 130px;
    flex-shrink: 0;
    max-height: 85vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 2%;
    margin-right: 12%;
}

.requested-item-feature-description-extra-long {
    height: 279px;
}

.requested-item-feature-description-long {
    height: 203px;
}

.seller-notified-text-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 2%;
}

.seller-notification-text {
    color: #FD9340;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}

.request-item-button-container {
    display: flex;
    margin-left: 56px;
    gap: 60px;
    margin-bottom: 9%;
    margin-top: 3%;
}

.sucess-button-style {
    width: 327px;
    height: 60px;
    border: none;
    border-Radius: 30.5px;
    background-color: #FD9340;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--greyscale-black-white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
}

.sucess-button-style img {
    width: 55px;
    height: 55px;
}

.close-button-style {
    width: 327px;
    height: 60px;
    border: none;
    border-Radius: 30.5px;
    background-color: #FD9340;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--greyscale-black-white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
}

.close-button-style img {
    width: 55px;
    height: 55px;
}

.category-type-and-edit-button-container {
    display: flex;
    justify-content: space-between;
}

.requested-item-feature-edit {
    margin-right: 5%;
    cursor: pointer;
}

.requirement-text {
    color: #FD9340;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    float: right;
    margin-block-start: 1.67em;
    margin-block-end: 1.97em;
}

@media (max-width: 1024px) {
    .requested-item-detail-container {
        margin-left: 30px;
    }

    .requested-item-detail-container-body {
        margin-left: 56px;
    }

    .requested-item-feature-container {
        margin-left: 50px;
    }
}

@media (max-width: 768px) {
    .requested-item-detail-container {
        margin-left: 0px;
        margin-top: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 100%;
    }

    .requested-item-detail-container-body {
        margin-left: 0px;
    }

    .requested-item-container-header {
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .requested-item-detail-container-main {
        flex-direction: column;
    }

    .requested-item-container-main-left {
        align-self: center;
    }

    .requested-item-detail-back {
        margin-left: 19px;
    }

    .requested-item-feature-edit {
        position: absolute;
        right: 19px;
    }

    .requested-item-feature-container {
        margin-left: 2%;
        width: 100%;
        display: contents;
    }

    .seller-notified-text-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-right: 10px;
        margin-top: 50%;
    }

    .requirement-text {
        display: flex;
        font-size: 12px;
        justify-content: center;
        font-family: PoppinsSemiBold;
    }

    .request-item-button-container {
        display: flex;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 1%;
        justify-content: space-between;
        gap: 25px;
    }

    .sucess-button-style {
        border-Radius: 16px;
    }

    .close-button-style {
        border-Radius: 16px;
    }

    .category-type-and-edit-button-container {
        margin-left: 19px;
        margin-right: 19px;
        margin-bottom: 8px;
    }

    .requested-item-feature-description {
        margin-left: 19px;
        margin-right: 19px;
        font-size: 10.815px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        height: 100%;
    }

    .requested-item-feature-parameters {
        margin-left: 10px;
        margin-right: 10px;
        height: 100%;
        border-radius: 16.898px;
        padding-block: 16px;
        padding-left: 11px;
    }

    .requested-item-feature-trade {
        margin-top: 39px;
    }

    .requested-item-feature-parameter {
        font-size: 10.815px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }

    .merchant-location-container{
        font-size: 10.815px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }

    .requested-item-feature-header-title {
        font-size: 10.815px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
    }

    .requested-item-feature-quantity {
        color: var(--greyscale-black-100, #000);
        font-family: Poppins;
        font-size: 13.519px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        padding-left: 31px;
    }

    .requested-item-feature-price {
        color: var(--greyscale-black-100, #000);
        font-family: Poppins;
        font-size: 13.519px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        padding-right: 31px;
    }

    .requested-item-category-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 75.02px;
    }

    .loading-spinner-container {
        padding-top: 20%;
    }

    .requested-item-page {
        min-height: 57vh;
    }

    .sucess-button-style img {
        width: 50px;
        height: 50px;
    }

    .close-button-style img {
        width: 50px;
        height: 50px;
    }
}