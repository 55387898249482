.product-preview-root {
  overflow-x: hidden;
}

.product-preview-container {
  background-color: #FFFFFF;
  overflow-x: hidden;
}

.publish-button-style-m1 {
  display: inline-flex;
  padding: 14px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  background: var(--main-color-100, #FD9340);
  border: none;
  float: right;
  margin-right: 36px;
  margin-bottom: 42px;
  cursor: pointer;
  width: 96px;
  height: 56px;
}

.save-draft-style-m1 {
  width: 117.479px;
  display: flex;
  height: 36.925px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #FD9340;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 20px;
  cursor: pointer;
}

.save-draft-text-style-m1 {
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
.save-draft-style-m1 img{
  width: 40px;
}

.save-draft-text-style-m1::before {
  content: 'Save Draft';
}

.delete-container-style-one {
  margin-left: 9.52px;
  cursor: pointer;
}

.preview-product-edit {
  cursor: pointer;
}

.publish-text-style-m1 {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  /* justify-content: ; */
}

.disable {
  opacity : 0.5;
  pointer-events: none;
}

.publish-button-style-m1 img {
  width: 35px;
  height: 25px;
  padding: 0px 11px;
}

.preview-action-container-root {
  width: 100%;
}

.preview-action-container {
  display: flex;
  float: right;
  margin-right: 36px;
  align-items: center;
}

@media (max-width: 768px) {
  .product-preview-root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-preview-container {
    background-color: #FFFFFF;
    flex-shrink: 0;  
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .preview-action-container {
    flex-direction: column-reverse;
    margin-right: 0px;
    margin-left: 18px;
  }
  .save-draft-text-style-m1::before {
    content: 'Save';
  }
  .save-draft-text-style-m1 {
    font-size: 14px;
  }
  .save-draft-style-m1 {
    width: 45px;
    height: 37px;
    margin-top: 17px;
    margin-left: 0px;
  }
  .preview-product-edit {
    margin-top: 17px;
  }
  .delete-container-style-one {
    margin-left: 0px;
  }
  .preview-main-action-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
    margin-top: 70px;
  }
  .publish-button-style-m1 {
    float: none;
    margin-right: 0px;
    margin-bottom: 0px;
  }
}