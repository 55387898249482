.mainParent1 {
}

.footerPadding {
  margin-top: 30px;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 25%;
}

.mainlogo-image {
  height: 100%;
}

.linkNbutton {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* width:26%; */
}

.logIn-text {
  font-size: 18px;
  font-family: "PoppinsSemiBold";
  color: black;
  width: max-content;
  margin-top: 20%;
}

.logIn-link {
  text-decoration: none;
  color: #000000;
}

.mainButton {
  width: max-content;
  height: 45px;
  padding: 14px 20px;
  margin-top: 15px;
  background-color: #fd9340;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  font-family: "Poppins";
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-left: 32px;
  margin-right: 5px;
}

.nextDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  margin: 4% 0% 3% 0%;
}

.titleContainer {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

.buttondiv {
  width: max-content;
  display: flex;
  justify-content: flex-end;
}

.mainblackButton {
  display: inline-flex;
  justify-items: center;
  align-items: center;
  width: max-content;
  margin-bottom: 4em;
  text-align: center;
  padding: 14px 20px;
  margin-top: 30px;
  line-height: 160%;
  background-color: #fff;
  font-size: 20px;
  font-weight: 500;
  color: #fd9340;
  font-family: "Poppins";
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.main-page-google-play-button {
  border-radius: 10px;
  cursor: pointer;
  padding: 14px 20px;
  background-color: #000000;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-decoration: none;
}

.google-play-button-icon-styles {
  width: 44px;
  height: 44px;
}

.google-play-button-icon-styles img {
  width: 100%;
  height: 100%;
}

.google-play-button-text-styles {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  gap: 2px;
}

.google-play-button-text-styles span:first-child {
  font-size: 16px;
}

.google-play-button-text-styles span:last-child {
  font-size: 30px;
}

.titleText {
  font-size: 60px;
  font-family: "Poppins";
  margin-block-end: 0em;
  margin-block-start: 0em;
}

.MainImgContainer {
  width: 70%;
  min-height: 509px;
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 7%;
  border: 1px;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutApp {
  background-color: #fd9340;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  padding-top: 3%;
}

.aboutAppTextHeading {
  font-size: 36px;
  line-height: 160%;
  text-align: center;
  color: #fff;
  font-family: "Poppins";
  width: 90%;
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  padding-bottom: 1%;
}

.aboutAppTextContent {
  font-size: 36px;
  line-height: 160%;
  text-align: center;
  color: #fff;
  font-family: "Poppins";
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-block-start: 0em;
  margin-block-end: 0.5em;
  padding-bottom: 3%;
}

.serviceContainer {
  display: flex;
  padding-top: 2%;
  justify-content: center;
}

.serviceText {
  font-size: 40px;
  font-family: "PoppinsSemiBold";
}

.ImgAndDescContainer {
  display: flex;
  margin-top: 2%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.mainServiceContainer {
  width: 80%;
  height: 283px;
  background-color: #fd9340;
  display: flex;
  align-items: center;
}

.mainServiceText {
  width: 50%;
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-align: center;
}

.secondLoginContainer {
  width: 100%;
  background-color: #fd9340;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.6%;
  margin-bottom: 6%;
}

.login-text-style {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin-block-start: 1.9em;
  margin-block-end: 0em;
}

.serviceaiamg-style {
  width: 50%;
  height: 283px;
}

.uexcessText {
  font-weight: bold;
  font-size: 50px;
}

.titleText .mobile-font-size {
  font-size: 40px;
}

@media (min-width: 1300px) {
  .titleText {
    font-size: 64px;
  }

  .titleText .mobile-font-size {
    font-size: 48px;
  }
}

@media screen and (min-width: 769px) {
  .mobileButtonDiv {
    display: none;
  }
}

@media (max-width: 768px) {
  .mainblackButton {
    font-size: 16px;
    background-color: #000000;
    color: #fff;
    margin-top: unset;
    margin-bottom: unset;
    height: 100%;
  }

  .google-play-button-icon-styles {
    width: 24px;
    height: 24px;
  }

  .google-play-button-text-styles span:last-child {
    font-size: 16px;
  }
  .google-play-button-text-styles span:first-child {
    font-size: 12px;
  }

  .mobileButtonDiv {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 38px;
    margin-top: 16px;
  }

  .mainButton {
    font-size: 14px;
    margin-left: 5px;
  }

  .logIn-text {
    font-size: 14px;
  }

  .titleText {
    font-size: 20px;
    line-height: 140%;
    margin-block-start: 0.4em;
  }

  .titleText .mobile-font-size {
    font-size: 20px;
  }

  .titleContainer {
    text-align: unset;
  }

  .aboutApp {
    padding-top: 37px;
    padding-bottom: 70px;
  }

  .MainImgContainer {
    width: 100%;
    min-height: 237px;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: 14%;
  }

  .buttondiv {
    display: none;
  }

  .login-text-style {
    display: none;
  }

  .secondLoginContainer {
    background-color: unset;
  }

  .aboutAppTextContent {
    font-size: 16px;
    margin-block-start: 1em;
  }

  .aboutAppTextHeading {
    font-size: 16px;
  }

  .uexcessText {
    font-size: 24px;
  }

  .serviceText {
    font-size: 20px;
    margin-block-end: 0.4em;
    margin-block-start: 2.4em;
  }

  .ImgAndDescContainer {
    background-color: #fd9340;
    gap: 5px;
    height: 1278px;
    justify-content: unset;
    padding-top: 6%;
    padding-bottom: 5%;
  }

  .mainServiceContainer {
    flex-direction: column;
    height: unset;
    background-color: unset;
  }

  .mainServiceText {
    font-size: 20px;
    width: unset;
    margin-block-start: 0em;
    margin-block-end: 0.8em;
  }

  .serviceaiamg-style {
    width: 92%;
    height: 200px;
    margin-bottom: 5%;
  }

  .firstServiceDescText {
    margin-block-start: 1em;
  }

  .thirdServiceaiamg-style {
    height: 200px;
    margin-bottom: 18%;
  }
}

@media (min-width: 300px) and (max-width: 464px) {
  .mainlogo-image {
    width: 152px;
  }
}
