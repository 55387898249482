.editPageContainer {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

}

.editFormScrollableContent {
  max-height: 100vh;
  overflow-y: auto;
}

.editForm {
  min-width: 35%;
  min-height: 300px;
  /* max-height: 600px; */
  background-color: #E5E4E3;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.contact-no-text-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.email-text-style {
  display: flex;
  flex-direction: row;
  margin-top: -3.6px;
  justify-content: space-between;
}

#companyName {
  font-size: 20px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  text-align: left;
}

#companyinput {
  width: 95%;
  padding: 10px;
  margin-top: 15px;
  color: #B3B1B0;
  border: 1px solid #E5E4E3;
  border-radius: 10px;
  outline: none;
}

.about-container {
  font-family: 'Poppins';
  margin-top: 5%;
  text-align: left;
  width: 100%;
}

#about {
  font-size: 20px;
  font-style: normal;
}

#aboutinput {
  width: 97%;
  font-family: "Poppins";
  min-height: 70px;
  padding: 2px;
  padding-left: 10px;
  margin-top: 7px;
  color: #B3B1B0;
  border: 1px solid #E5E4E3;
  border-radius: 10px;
  outline: none;
  resize: none;
  overflow-y: auto;
}

#location {
  font-size: 20px;
  font-style: normal;
  font-family: "Poppins";
  display: flex;
  text-align: left;
  margin-top: 4%;
}

#locationinput {
  width: 97%;
  font-family: "Poppins";
  min-height: 95px;
  padding: 2px;
  padding-left: 10px;
  margin-top: 7px;
  color: #B3B1B0;
  border: 1px solid #E5E4E3;
  border-radius: 10px;
  outline: none;
  resize: none;
  overflow-y: auto;
}

#contact {
  color: var(--greyscale-black-100, #000);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 3%;
  width: 169.043px;
}

#contactinput {
  font-family: "Poppins";
  min-height: 15px;
  width: 77%;
  padding: 2px;
  padding-left: 10px;
  margin-top: 7px;
  color: #B3B1B0;
  border: 1px solid #E5E4E3;
  border-radius: 10px;
  outline: none;
  resize: none;
  overflow-y: auto;
  margin-right: 1%;
}

#email {
  color: var(--greyscale-black-100, #000);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 3%;
  width: 169.043px;
}

#emailinput {
  font-family: "Poppins";
  min-height: 15px;
  width: 77%;
  padding: 2px;
  padding-left: 10px;
  margin-top: 7px;
  color: #B3B1B0;
  border: 1px solid #E5E4E3;
  border-radius: 10px;
  outline: none;
  resize: none;
  overflow-y: auto;
  pointer-events: none;
  margin-right: 1%;
}

.savebutton-wrapper {
  margin-top: 7%;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
}

.editForm button {
  width: 40%;
  height: 45px;
  padding: 10px 15px;
  background-color: #B3B1B0;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: auto;
}

.editForm button.active {
  background-color: #FD9340;
  cursor: pointer;
}

@media (max-width: 768px) {
  .editPageContainer {
    /* height: 100vh; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .editForm {
    /* min-width: 90%; */
    /* min-height: 300px;
    max-height: 750px; */
    background-color: #E5E4E3;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    padding: 2%;
    margin-left: -28px;
    margin-right: -27px;

  }

  .contact-no-text-style {
    display: flex;
    flex-direction: column;
  }

  .email-text-style {
    display: flex;
    flex-direction: column;
  }

  #companyName {
    font-size: 16px;
    font-family: "Poppins";
    font-style: normal;
    display: flex;
    text-align: left;
    margin-left: 3%;
    margin-top: 3%;
  }

  #companyinput {
    width: 86%;
    height: 26px;
    padding: 10px;
    margin-top: 2%;
    margin-left: 4%;
    color: #B3B1B0;
    border: 1px solid #E5E4E3;
    border-radius: 10px;
    outline: none;
  }

  #contact {
    font-size: 16px;
    font-family: "Poppins";
    font-style: normal;
    display: flex;
    text-align: left;
    margin-top: 4%;
    margin-left: 3%;
    width: fit-content;
  }

  #email {
    font-size: 16px;
    font-family: "Poppins";
    margin-top: 4%;
    font-style: normal;
    display: flex;
    text-align: left;
    margin-left: 3%;
  }

  #contactinput {
    width: 86%;
    height: 26px;
    padding: 10px;
    margin-top: 2%;
    margin-left: 4%;
    color: #B3B1B0;
    border: 1px solid #E5E4E3;
    border-radius: 10px;
    outline: none;
  }

  #emailinput {
    width: 86%;
    height: 26px;
    padding: 10px;
    margin-top: 2%;
    margin-left: 4%;
    color: #B3B1B0;
    border: 1px solid #E5E4E3;
    border-radius: 10px;
    outline: none;
  }

  #location {
    font-size: 16px;
    font-style: normal;
    font-family: "Poppins";
    display: flex;
    text-align: left;
    margin-top: 4%;
    margin-left: 3%;
  }

  #locationinput {
    width: 88%;
    font-family: "Poppins";
    min-height: 95px;
    padding: 2px;
    padding-left: 10px;
    margin-top: 2%;
    margin-left: 4%;
    color: #B3B1B0;
    border: 1px solid #E5E4E3;
    border-radius: 10px;
    outline: none;
    resize: none;
    overflow-y: auto;
    font-size: 16px;
  }

  #about {
    font-size: 16px;
    font-style: normal;
    margin-left: 4%;
  }

  #aboutinput {
    width: 88%;
    font-family: "Poppins";
    min-height: 70px;
    padding: 2px;
    padding-left: 10px;
    margin-top: 2%;
    margin-left: 4%;
    color: #B3B1B0;
    border: 1px solid #E5E4E3;
    border-radius: 10px;
    outline: none;
    resize: none;
    overflow-y: auto;
    height: 93px;
    font-size: 16px;
  }

  .savebutton-wrapper {
    margin-top: 13px;
    margin-bottom: 5%;
    display: flex;
    justify-content: center;
    width: 229%;
    margin-left: -64.5%;
  }
}