.navbar {
  height: 50px;
  width: 100%;
  display: flex;
  background-color: black;
  display: flex;
  align-items: center;
  position: relative;
}

.navbarItems {
  display: flex;
  width: 30%;
  margin-left: 3.5%;
}

.find-material-open-dropdown-icon {
  transform: rotate(180deg);
  margin-bottom: 6% !important;
}

.mobilenavbaritems {
  display: flex;
  width: 30%;
}

.navbarItems button {
  padding: 5px 30px 5px 30px;
  margin: 0;
  color: white;
  font-family: "PoppinsSemiBold";
  font-size: 13px;
  word-spacing: 2px;
  letter-spacing: 1px;
  background-color: black;
  cursor: pointer;
  border: 2px solid #fd9340;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15%;
}

.find-material-dropdown-icon {
  margin-top: 8%;
}

.mobilenavbaritems button {
  padding: 5px 20px 5px 20px;
  margin: 0;
  color: white;
  font-family: "PoppinsSemiBold";
  font-size: 11px;
  word-spacing: 2px;
  letter-spacing: 1px;
  background-color: black;
  border: none;
  cursor: pointer;
  margin-right: 30px;
  margin-left: 10px;
  border: 2px solid #fd9340;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.navbarItems button.button-hovered {
  font-family: "PoppinsBold";
  padding: 5;
  margin: 0;
  color: white;
  font-size: 15px;
  word-spacing: 2px;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 9px;
  padding-right: 15px;
  background-color: transparent;
  border: 2px solid #fd9340;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15%;
}

.mobilenavbaritems button.button-hovered {
  font-family: "PoppinsBold";
  margin: 0;
  color: white;
  font-size: 13px;
  word-spacing: 2px;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid #fd9340;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-left: 5%;
}

.category-list {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #e5e4e3;
  border-radius: 16px;
  margin-top: 42px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: black;
  width: 80%;
  max-height: 280px;
  z-index: 1;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: scroll;
}

.category-dropdown-buttons {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: auto;
  padding-top: 10px;
  max-height: 270px;
}

.category:hover + .category-list,
.category-list:hover {
  display: block;
}

.category-list button {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  background-color: white;
  border: none;
  color: black;
  font-family: "PoppinsBold";
  border-radius: 20px;
  padding: 0;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  margin-left: 3%;
  margin-right: 3%;
  cursor: pointer;
}

.category-list button:hover {
  transition: background-color 0.3s, color 0.3s;
  background-color: #fd9340;
  color: white;
}

.category-buttons-container {
  display: flex;
  flex-wrap: nowrap;
  margin-left: 15px;
  margin-top: 0%;
  width: 30%;
  height: 100%;
}

.mobile-category-buttons-container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0%;
  width: 30%;
  height: 100%;
}

.category-buttons-container .category {
  white-space: nowrap;
  margin-right: 15px;
}

.mobile-category-buttons-container .category {
  white-space: nowrap;
}

.find-material-dropdown-icon{
  margin-top: 6% !important;
}

@media (max-width: 768px) {
  .category-dropdown-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 20%;
    padding-top: 10px;
    max-height: 100%;
  }
  
  .category-list {
    margin-top: 36px;
  }

  .find-material-dropdown-icon {
    margin-top: 6%;
    width: 60%;
    height: 60%;
  }
  
}
