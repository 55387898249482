.go-backSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 234px;
    margin-left: 6%;
    margin-top: 20px;
}

.arrow-container{
height: 50px;
width: 45px;
border-radius: 30%;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
display: flex;
justify-content: center;
align-items: center;
}

.arrow-img{
    height: 30px;
    width: 30px;
}

.goBackHeading{
    font-family: 'PoppinsBold';
    font-size: 29px;
}

.pagination {
  display: flex;
  font-family: 'Poppins';
  color: var(--greyscale-black-30, #B3B1B0);
  font-size: 24px;
  margin-top: -5px;
  margin-left: -7%;
}

.pagination > span,
.pagination > span.active {
  margin: 0 5px;
  display: inline;
  font-size: 24px;
  color: #B3B1B0;
  cursor: pointer;
  transition: color 0.2s;
}

.pagination > span.active {
  color: #FD9340;
}

.draft-item-list-container {
  display: flex;
  flex-wrap: wrap;
}

.draft-item-result-container {
  display: flex;
  justify-content: center;
  padding-top: 3%;
  padding-bottom: 2%
}
