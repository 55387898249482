.loginpage-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: gray;
}

.login-content {
  width: 80.74%;
  height: 75.44%;
  margin: 7.66%;
  background-color: white;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  max-width: 100%;
}

.welcomelogo-image {
  position: absolute;
  width: 210px;
  height: 105px;
  z-index: 1;
}

.welcomeScrap-image {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.image-container-new {
  width: 45%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-shrink: 0;
}

.login-form-container {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 0% 8%;
  overflow: auto;
}

.welcomeForm {
  height: 100%;
  padding: 3% 0%;
  overflow-y: auto;
  @media (min-height: 950px) {
    padding: 10% 0%;
  }
}

.helloText,
.welcomeText {
  font-family: "PoppinsSemiBold";
  font-size: 38px;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
}

.loginpage-eye-icon {
  color: #b3b1b0;
  cursor: pointer;
  position: absolute;
  right: 4%;
  transform: translateY(-50%);
  top: 52%;
}

.getstarted-loading-inlogin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.spantext {
  color: #fd9340;
  font-weight: 700;
  font-size: 12px;
}

.forgot-password-text-style {
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-decoration: none;
}

.forgot-password {
  color: #ff4d4f;
  font-size: 12px;
  margin: 8px;
  font-family: "Poppins";
  text-decoration: none;
}

.logginError {
  color: #ff4d4f;
  font-size: 12px;
  margin: 0;
  font-family: "Poppins";
  margin-left: 8px;
}

.modal-content form a {
  color: var(--main-color-100, #fd9340);
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-decoration: none;
}

.button-wrapper {
  margin-top: 10px;
}

.success-container {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  border: none;
}

.containerColor {
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 25px;
  border: 7px solid #fff;
  background: #fd9340;
  overflow: auto;
}

.star {
  width: 12%;
  height: 12%;
  position: absolute;
  bottom: 83.5%;
  right: 68%;
}

.stars {
  width: 8%;
  height: 8%;
  position: absolute;
  bottom: 7%;
  right: 22%;
}

.sucessText {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.sucessLogo {
  display: inline-flex;
  padding: 15.455px 30.909px;
  align-items: flex-end;
}

.success-message {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.verifybutton-wrapper {
  margin-top: 120px;
}

.getStartedButton {
  width: calc(66% + 30px);
  height: 50px;
  flex-shrink: 0;
  padding: 15px;
  margin-top: 6%;
  background-color: #b3b1b0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  font-family: "Poppins";
  line-height: 1;
  border: none;
  border-radius: 30.5px;
}

.login-email-container {
  width: 66%;
  padding: 15px;
  margin-top: 10%;
  color: #b3b1b0;
  border: 1px solid #e5e4e3;
  font-family: "Poppins";
  border-radius: 16px;
  outline: none;
}

.login-password-container {
  width: 66%;
  padding: 15px;
  margin-top: 14px;
  border: 1px solid #e5e4e3;
  font-family: "Poppins";
  border-radius: 16px;
  position: relative !important;
}

.login-password-container input {
  width: 90%;
  outline: none;
  border: none;
  color: #b3b1b0;
  height: 100%;
  padding-inline: 0px;
}

.getStartedButton.active {
  background-color: #fd9340;
}

.getStartedButton:hover {
  background-color: #fd9343;
}

.buttonloading-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.create-account-text-login {
  font-size: 11px;
  font-family: "Poppins";
  padding-top: 10px !important;
  width: 66%;
  padding: 0px 15px;
  margin-block-start: 10px;
  margin-block-end: 0px;
  text-align: center;

  @media (max-height: 670px) {
    margin-top: 10px;
  }
}

.create-account-link {
  color: #fd9340;
  text-decoration: none;
  font-family: "PoppinsBold";
}

@media (max-width: 768px) {
  .login-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0px;
    overflow: auto;
    border-radius: 0px;
  }

  .login-password-container input {
    width: 90%;
    margin-right: 10%;
  }

  .image-container-new {
    width: 100%;
  }

  .welcomeScrap-image {
    object-fit: cover;
    height: 202px;
    width: 100%;
    margin-bottom: auto;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .welcomelogo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 210px;
    height: 105px;
    z-index: 1;
  }

  .welcomeForm {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .login-form-container {
    overflow: unset;
    margin: 0% 5%;
  }

  .login-email-container {
    width: 90%;
    height: 36px;
  }

  .login-password-container {
    width: 90%;
    height: 36px;
  }

  .forgot-password {
    margin-right: 60%;
  }

  .getStartedButton {
    width: calc(90% + 30px);
    height: calc(36px + 30px);
  }

  .create-account-text-login {
    width: 90%;
    margin-top: 10px;
  }

  .welcomeText {
    margin-bottom: 3%;
  }

  .loginpage-container {
    background-color: white;
  }

  .containerColor {
    width: 82%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 25px;
    border: 7px solid #fff;
    background: #fd9340;
    overflow: auto;
  }

  .star {
    width: 10%;
    height: 10%;
    position: absolute;
    bottom: 70%;
    right: 85%;
  }

  .stars {
    width: 7%;
    height: 7%;
    position: absolute;
    z-index: 2;
    top: 71%;
    left: 87%;
  }

  .sucessText {
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  .sucessLogo {
    display: inline-flex;
    padding: 5% 10%;
    align-items: flex-end;
  }

  .success-message {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

@media (max-width: 413px) {
  .containerColor {
    height: 70%;
  }

  .star {
    top: 11%;
    width: 15%;
    left: 2%;
  }

  .stars {
    top: 81%;
    width: 12%;
    left: 84%;
  }
}
