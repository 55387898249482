/* .inquiry-container-root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
} */
.inquiry-container-main {
  background-color: #FFFFFF;
  overflow-y: auto;
}

.inquiry-description {
  color: var(--greyscale-black-30, #B3B1B0);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}

.product-buyer-button {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  justify-self: center;
  padding-block: 14px;
  padding-inline: 9.6px;
  margin-right: 14.65px;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 66px;
  margin-top: 20px;
}

.product-buyer-button-text {
  color: #FD9340;
  /* Poppins/SemiBold/16 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 17.6px */
}

@media (max-width: 768px) {
  .inquiry-container-root {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inquiry-container-main {
    background-color: #FFFFFF;
    flex-shrink: 0;  
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .inquiry-main-action-container {
    margin-top: 24px;
    padding-inline: 22px;
  }
  .inquiry-description {
    font-size: 16px;
  }
  .buyer-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}