.buyer-req-item-wrapper {
    padding: 7px 5px;
    display: flex;
    flex-direction: column;
    width: 90%;
    border-bottom: 1.5px solid #D9D9D9;
    cursor: default;
}

.buyer-req-item-container {
    padding: 10px 30px;
}

h2,h4,h6 {
    margin: 0;
}

.buyer-req-item-container:hover {
    background-color: #F8F8F8;
    cursor: pointer;
}

.buyer-req-time {
    color: #B3B1B0;
    font-family: "PoppinsSemiBold";
    font-size: 16px;
}

.buyer-req-item-name {
    font-family: "PoppinsBold";
    margin-top: 3px;
    font-size: 32px;
}

.buyer-req-description {
    font-family: "Poppins";
    font-size: 20px;
    margin: 10px 0 5px 0;
    color: #B3B1B0;
}

.view-more-button {
    border: none;
    color: #FD9340;
    font-family: "PoppinsSemiBold";
    width: 60px;
    cursor: pointer;
    background-color: transparent;
    font-size: 20px;
}

.buyer-req-location {
    font-family: "PoppinsSemiBold";
    font-size: 20px;
    margin: 5px 0 25px 0;
    color: #B3B1B0;
}

.buyer-req-description.collapsed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.buyer-req-description.expanded {
  white-space: normal;
}

@media (max-width: 768px) {
    .buyer-req-time {
        font-size: 12px;
    }

    .buyer-req-item-name {
        font-family: "PoppinsBold";
        margin-top: 3px;
        font-size: 20px;
    }

    .buyer-req-description {
        font-size: 15px;
        margin: 5px 0 3px 0;
        color: #B3B1B0;
    }

    .view-more-button {
        width: 50px;
        font-size: 15px;
    }

    .buyer-req-location {
        font-family: "PoppinsSemiBold";
        font-size: 15px;
        margin: 5px 0 10px 0;
        color: #B3B1B0;
    }

    .buyer-req-item-container {
        padding: 5px 17px;
    }

    .buyer-req-item-wrapper {
        padding: 4px 5px;
    }
}
