.notification-root-container {
  margin-inline: 90px;
  margin-top: 37px;
}

.notification-header {
  display: flex;
  align-items: center;
}

.notification-text {
  color: #000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'PoppinsBold';
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 64px;
  margin-left: 21px;
}

.notification-list-container {
  margin-top: 39px;
  margin-left: 67px;
  min-height: 44vh;
}

.notification {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.row-container text {
  text-decoration: none;
}

.row-container {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  /* padding: 10px; */
  border-radius: 25px;
  height: auto;
  flex-shrink: 0;
  padding-block: 30px;
  padding-left: 30px;
  margin-bottom: 28px;
  cursor: pointer;
}

.row-container img {
  /* margin-right: 10px;
  margin-left: 2%; */
}

.row-container p {
  margin: 0;
  font-size: 24px;
  font-family: Poppins;
  align-items: center;
  justify-content: center;
  /* margin-left: 20%; */
  color: #B3B1B0;
  /* width: 60% */
}

.row-container text {
  margin: 0;
  font-size: 16px;
  font-family: Poppins;
  align-items: center;
  justify-content: center;
  color: #B3B1B0;
  /* margin-right: 5%; */
}

.pagination1 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-family: poppins;
  color: var(--greyscale-black-30, #B3B1B0);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.pagination1>li {
  margin: 0 5px;
  display: inline;
  font-size: 24px;
  color: #B3B1B0;
  cursor: pointer;
}

.pagination1>li.active {
  color: orange;
}

.notification {
  text-decoration: none;
  margin-left: 10%;
}

.notification:visited {
  text-decoration: none;
}

.notification:active {
  text-decoration: none;
}

.no-underline {
  text-decoration: none;
}

.modal90 {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.modal-content90 {
  background-color: white;
  justify-content: space-evenly;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32.5%;
  max-height: 85vh;
  overflow-y: auto;
  padding: 20px;
  width: 1237px;
  height: 696px;
  flex-shrink: 0;
}

.paragraphtextrow {
  width: 100%;
  margin-left: 10px;

  color: #B3B1B0;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
}

.notification-date-container {
  /* width: 100%; */
  align-items: end;
  justify-content: end;
}

.notificationdate {
  color: var(--greyscale-black-30, #B3B1B0);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  width: 153px;
}

@media (max-width: 768px) {
  .notification-root-container {
    margin-inline: 13px;
    margin-top: 8px;
  }
  
  .notification-list-container {
    margin-top: 29px;
    margin-left: 20px;
  }

  .row-container {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    /* padding: 10px; */
    border-radius: 25px;
    height: auto;
    flex-shrink: 0;
    padding-block: 2.38px;
    padding-left: 3.81px;
    margin-bottom: 13px;
  }

  .paragraphtextrow {
    font-size: 8px;
  }

  .notificationdate {
    font-size: 7px;
    width: 50px;
    margin-right: 8px;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  .notification-text {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-shadow: none;
  }

  .imgrow {
    width: 34.244px;
    height: 34.244px;
    flex-shrink: 0;
    fill: #FFF;
  }

  .row-container p {
    width: 60%;
    height: 17px;
    flex-shrink: 0;
    color: var(--greyscale-black-30, #B3B1B0);
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-left: 3%;

  }

  .row-container text {
    width: 15%;
    height: 7px;
    flex-shrink: 0;
    color: var(--greyscale-black-30, #B3B1B0);
    text-align: center;
    font-family: Poppins;
    font-size: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-left: 5%;
    margin-bottom: 2.7%;
  }

  .backpressfornotification {
    /* width: 46px;
    height: 46px; */
  }

  .pagination1>li {
    text-align: center;
    margin-left: 4%;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}

/* @media (min-width: 768px)and (max-width:1280px) {
  .row-container {
    width: 90%;
    height: auto;
  }

  .imgrow {
    width: 44.244px;
    height: 44.244px;
    flex-shrink: 0;
    fill: #FFF;
  }

  .row-container p {
    font-size: 16px;
    margin-left: 3%;
  }

  .row-container text {
    width: 15%;
    font-size: 16px;
    margin-left: 5%;
    align-items: center;
    margin-bottom: 0.2%;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }
} */
