.buyer-request-form-container {
  margin: 1% 5% 1% 5%;
  background-color: #f8f8f8;
  border-radius: 25px;
}

.request-heading {
  padding: 5% 0% 3.8% 0%;
  font-size: 48px;
  color: #000000;
  font-family: "PoppinsBold";
  margin-top: 5%;
}

.main-request-heading {
  padding-top: 3%;
  padding-left: 5%;
}

.make-request-button-container {
  margin-top: 3%;
  margin-left: 5%;
  padding-bottom: 4%;
}

.make-request-button {
  border-radius: 30px;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 24px;
  font-family: "PoppinsBold";
  color: #ffffff;
  background-color: #fd9340;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.horizontal-line {
  border: 1px solid #d9d9d9;
  margin: 0% 1% 0% 1%;
}

.request-container {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-family: "PoppinsBold";
  margin-top: 2%;
  cursor: pointer;
}

.active-button.active {
  color: #000000;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.active-button {
  color: #b3b1b0;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.success-button {
  color: #b3b1b0;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.success-button.active {
  color: #000000;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.close-button {
  color: #b3b1b0;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.close-button.active {
  color: #000000;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.underline {
  border: 5px solid #fd9340;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 50%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-left: 25%;
  margin-top: 3%;
  flex: 1;
  text-align: center;
}

.categories-row-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.categories-container {
  width: 83%;
  margin: 10px 0 10px 0;
  margin-left: 5%;
  margin-right: 5%;
}

.active-category-styling {
  background-color: white;
  padding: 5px 25px;
  margin-right: 20px;
  border: 1px solid #fd9340;
  color: #fd9340;
  border-radius: 20px;
  font-size: 15px;
  font-family: "PoppinsBold";
}

.request-loader-container {
  min-height: 300px;
  margin-top: 3%;
}

.request-content {
  align-items: flex-start;
  text-align: center;
  justify-content: center;
  display: flex;
  padding-top: 5%;
}

.other-category-styling {
  background-color: white;
  padding: 5px 25px;
  margin-right: 20px;
  border: 1px solid #b3b1b0;
  color: #b3b1b0;
  border-radius: 20px;
  font-size: 15px;
  font-family: "PoppinsBold";
}

.category-styling {
  margin: 20px 0 0 0;
}

.category-styling {
  padding: 5px 25px;
  margin-right: 20px;
  border-radius: 20px;
  font-size: 16px;
  font-family: "PoppinsBold";
  cursor: pointer;
}

.no-request-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #b3b1b0;
  font-family: "Poppins";
  text-align: center;
}

.end-line {
  border: 1px solid #d9d9d9;
  margin: 1% 3% 1% 0%;
}

.details-container {
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  margin-top: 3%;
}

.posting-time-container {
  color: #b3b1b0;
  font-size: 16px;
  font-family: "Poppins";
}

.request-heading-container {
  margin-top: 0.6%;
  font-size: 32px;
  font-family: "PoppinsBold";
}

.request-description-container {
  color: #b3b1b0;
  font-size: 20px;
  font-family: "Poppins";
  margin-top: 1%;
  margin-bottom: 2%;
  display: flex;
  margin-right: 3%;
  flex-direction: column;
  line-height: 30px;
}

.hover-container {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  padding-right: 2%;
}

.hover-container:hover {
  background-color: #f8f8f8;
  margin-right: 3%;
}

.more-less-button {
  border-radius: 0px;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  font-family: 20px;
  padding-top: 10px;
  color: #fd9340;
}

.request-description-text {
  width: 95%;
}

.request-description-text.collapsed {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.request-description-text.expanded {
  white-space: normal;
}

.more-description-button {
  color: #fd9340;
  font-size: 20px;
  font-family: "Poppins";
  margin-top: 2%;
}

.location-details {
  color: #b3b1b0;
  font-size: 20px;
  font-family: "Poppins";
}

.buyer-requestform-go-backContainer {
  display: flex;
  align-items: center;
  margin-left: 5%;
  margin-top: 20px;
}

.request-header {
  color: #fd9340;
  font-family: "PoppinsBold";
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 64px;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .buyer-requestform-go-backContainer {
    margin-top: 7%;
    margin-left: 4%;
    margin-bottom: 5%;
  }

  .category-styling {
    font-size: 14px;
  }

  .request-loader-container {
    min-height: 200px;
    margin-top: 3%;
  }

  .details-container {
    margin-left: 8%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    min-height: 200px;
  }

  .request-content {
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 25%;
  }

  .make-request-button-container {
    margin-left: 0%;
  }

  .request-header {
    color: #000000;
    text-align: center;
    font-family: "PoppinsBold";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 147%;
    margin-left: 22px;
  }

  .buyer-request-form-container {
    margin: 3% 7% 1% 7%;
  }

  .request-heading {
    text-align: center;
    display: flex;
    font-size: 24px;
    display: flex;
    padding: 0%;
    margin-top: 1%;
    justify-content: center;
    margin-left: 0%;
    font-family: "PoppinsSemiBold";
  }

  .main-request-heading {
    padding-top: 3%;
    padding-left: 0%;
  }

  .make-request-button-container {
    margin-top: 3%;
    padding-bottom: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .make-request-button {
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    font-family: "Poppins";
  }

  .request-container {
    font-size: 16px;
    margin-top: 7%;
  }

  .categories-scroll-container {
    margin-left: 4%;
  }

  .categories-container {
    margin:0px 0 0 0;
  }

  .active-category-styling {
    background-color: #fd9340;
    color: white;
  }

  .other-category-styling {
    background-color: #efefef;
    color: #fd9340;
    border: 1px solid #fd9340;
  }

  .details-container {
    margin-left: 5%;
    display: flex;
    flex-direction: column;
  }

  .request-heading-container {
    font-size: 20px;
  }

  .posting-time-container {
    font-size: 12px;
    margin-top: 3%;
  }

  .request-description-container {
    font-size: 12px;
    margin-top: 2%;
    line-height: 20px;
  }

  .more-description-button {
    font-size: 12px;
  }

  .location-details {
    font-size: 14px;
    margin-top: 3%;
  }

  .categories-row-container {
    margin-left: 0%;
  }
}
