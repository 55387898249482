.product-publish-container {
   border-radius: 15px;
   background-color: #F8F8F8;
   margin-inline: 66px;  
   margin-top: 50px;
   padding-inline: 55px;
   padding-top: 29px;
   padding-bottom: 69px;
}

.filter-pagination-for-seller-page {
   display: flex;
   justify-content: center;
 }

.product-publish-header {
   color: #000;
   font-family: "Poppins";
   font-size: 64px;
   font-style: normal;
   font-weight: 700;
   line-height: 180%; /* 115.2px */
}

.product-publish-action-container {
   display: flex;
   margin-top: 18px;
}

.add-product-button{
   height: 60px;
   width: 327px;
   background-color: #FD9340;
   border-radius: 30.5px;
   border: none;
   color: var(--greyscale-black-white, #FFF);
   text-align: center;
   font-family: "Poppins";
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   line-height: 180%; /* 36px */
   cursor: pointer;
}

.draft-button{
   margin-left: 78px;
   padding: 14px 20px;
   border-radius: 16px;
   background: var(--main-color-100, #FD9340);
   color: #FFF;
   font-family: "Poppins";
   font-size: 16px;
   font-style: normal;
   font-weight: 500;
   line-height: 160%; /* 25.6px */
   border: none;
   cursor: pointer;
}

.herosection{
   display: flex;
   flex-direction: row;
}

.item-status-tab-container {
  display: flex;
  margin-left: 31px;
  margin-top: 35px;
}

.item-status-tab-button {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 327px;
   height: 60px;
   border-radius: 30.5px;
   border: 1px solid var(--main-color-100, #FD9340);
   background: #F8F8F8;
   color: var(--main-color-100, #FD9340);
   text-align: center;
   font-family: Poppins;
   font-size: 20px;
   font-style: normal;
   font-weight: 700;
   line-height: 180%;
   cursor: pointer;
}

.item-status-tab-button-selected {
   background-color: #FD9340;
   color: #FFF;
}

.item-status-tab-button-items {
   margin-left: 19px;
}

.item-list-container {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   min-height: 25vh;
   padding-top: 20px;
}

.category-item-buttons {
   width: 90%;
   margin: 30px 0 0 0;
}

.category-item-contents {
   width: 90%;
   margin: -10px 0 0 0;
}

.category-item-button {
   background-color: white;
   padding: 5px 25px;
   margin-right: 20px;
   border: 1px solid #B3B1B0;
   color: #B3B1B0;
   border-radius: 20px;
   font-size: 15px;
   font-family: "PoppinsBold";
   cursor: pointer;
}

.category-item-button-active {
   background-color: white;
   padding: 5px 25px;
   margin-right: 20px;
   border: 1px solid #FD9340;
   color: #FD9340;
   border-radius: 20px;
   font-size: 15px;
   font-family: "PoppinsBold";
}

.category-wrapper {
   width: 100%;
   display: flex;
   justify-content: center;
}

.buyer-req-empty-message {
   color: #B3B1B0;
   font-family: "PoppinsSemiBold";
   display: flex;
   height: 50px;
   padding-top: 5%;
   padding-bottom: 10%;
   align-items: center;
}

@media (max-width: 768px) {
   .product-publish-container {
      border-radius: 25px;
      margin-inline: 5px;  
      margin-top: 9px;
      padding-top: 12px;
      padding-bottom: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
   }
   .product-publish-header {
      color: #000;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 140%;
      white-space: nowrap;
   }
   
   .product-publish-action-container {
      margin-top: 17px;
   }

   .add-product-button{
      padding: 14px 20px;
      height: auto;
      width: auto;
      border-radius: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 160%;
      white-space: nowrap;
   }

   .draft-button{
      margin-left: 50px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      border: none;
   }

   .item-status-tab-button {
      width: 110px;
      height: 38px;
      border-radius: 30.5px;
      font-size: 12px;
   }

   .item-status-tab-button-items {
      margin-left: 10px;
   }

   .item-status-tab-container {
      display: flex;
      margin-left: 11px;
      margin-top: 15px;
    }

    .item-list-container {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 14px;
   }

   .category-item-buttons {
      width: 85%;
      margin: 10px 0;
   }

   .category-item-button {
      padding: 3px 15px;
      margin-right: 10px;
      font-size: 11px;
   }

   .category-item-button-active {
      padding: 3px 15px;
      margin-right: 10px;
      font-size: 11px;
   }

   .buyer-req-empty-message {
      font-size: 12px;
   }
}
