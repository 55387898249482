@import "normalize.css";

.signUp-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: gray;
}

.signUp-content {
  width: 80.74%;
  height: 75.44%;
  margin: 7.66%;
  background-color: white;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  max-width: 100%;
}

.signUpForm {
  height: 100%;
  padding: 3% 0% 2% 0%;
  overflow-y: auto;
  @media (min-height: 950px) {
    padding: 10% 0%;
  }
}

.signUplogo-image {
  position: absolute;
  width: 210px;
  height: 105px;
  z-index: 1;
  align-self: center;
}

.activeButton {
  cursor: pointer;
  background-color: #fd9340;
  position: relative;
}

.inactiveButton {
  cursor: default;
  background-color: #e5e4e3;
  position: relative;
}

.signUpScrap-image {
  width: 100%;
  border-radius: 25px;
  height: 100%;
}

.signUpImage-container {
  width: 45%;
  display: flex;
  position: relative !important;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-shrink: 0;
}

.signup-form-container {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 0% 8%;
  overflow: auto;
}

.signUp-content form {
  border: none;
  margin-top: 7px;
}

.createAccount {
  font-family: "PoppinsSemiBold";
  font-size: 38px;
  font-weight: 600;
  line-height: 140%;
  margin: 0;
  margin-top: 4%;
  word-wrap: break-word;
}

.signUp-content form label {
  width: 327px;
  height: 60px;
  flex-shrink: 0;
}

.signUp-content form input {
  width: 66%;
  padding: 15px;
  margin-top: 16px;
  color: #b3b1b0;
  border: 1px solid #e5e4e3;
  font-family: "Poppins";
  border-radius: 16px;
  outline: none;
}

.signUp-content form svg {
  margin-top: 1%;
  margin-right: 3%;
}

.signUp-content form span {
  color: var(--greyscale-black-30, #b3b1b0);
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.signUp-content form a {
  color: var(--main-color-100, #fd9340);
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-decoration: none;
}

.getStartButton {
  width: calc(66% + 30px);
  height: 50px;
  flex-shrink: 0;
  padding: 15px;
  background-color: #b3b1b0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  font-family: "Poppins";
  line-height: 1;
  border: none;
  border-radius: 30.5px;
}

.getstartedstyling {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.signUp-content form h2 {
  color: #fd9340;
  font-weight: 700;
  font-size: 12px;
}

.password-error-styling {
  color: #ff4d4f;
  font-family: "Poppins";
  font-size: 12px;
  margin-left: 8px;
}

.button-pointer {
  cursor: pointer;
}

.createacc-text {
  font-size: 11px;
  font-family: "Poppins";
  width: 66%;
  padding: 0px 15px;
  margin-block-start: 6%;
  margin-block-end: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.createaccount-link {
  color: #fd9340;
  text-decoration: none;
}

.signUp-content input[type="email"] {
  appearance: none;
  border: 1px solid #d9d9d9;
}

.loading-indicator {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.loading-dots {
  display: inline-block;
  animation: pulse 1s infinite;
  vertical-align: middle;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 3px;
  color: transparent;
}

#agreedToTerms {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px;
  background-color: transparent;
  border: 1.2px solid #e5e4e3;
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 0px !important;
}

.Terms-of-Service-text-style {
  display: flex;
  flex-direction: row;
  margin-top: 4.5%;
  margin-bottom: 4.5%;
  gap: 10px;
}

.Terms-of-Service-text-style p {
  margin: 0px;
}

#agreedToTerms:checked {
  background-color: #e5e4e3;
  border: 1.2px solid #e5e4e3;
}

#agreedToTerms::after {
  content: "";
  background: url("../images/CheckMark.svg") no-repeat center center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64%;
  height: 100%;
  background-size: contain;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

#agreedToTerms:checked::after {
  opacity: 1;
}

@media (max-width: 768px) {
  .signUp-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0px;
    overflow: auto;
    border-radius: 0px;
  }

  .signUpImage-container {
    width: 100%;
  }

  .signUpScrap-image {
    object-fit: cover;
    height: 202px;
    width: 100%;
    margin-bottom: auto;
    border-top-right-radius: 0%;
    border-top-left-radius: 0%;
  }

  .signUplogo-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 210px;
    height: 105px;
    z-index: 1;
  }

  .signup-form-container {
    align-self: center;
    overflow: unset;
    margin: 0% 5%;
  }

  .signUpForm {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-left: 0%;
    margin-top: 0px;
  }

  .createAccount {
    width: 100%;
    margin-bottom: 0px;
  }

  .signUp-content form input {
    width: 90%;
    height: 36px;
  }

  .signUp-content form {
    margin-top: 11px;
  }

  .getStartButton {
    width: calc(90% + 30px);
    height: calc(36px + 30px);
  }

  .createacc-text {
    width: 90%;
    margin-top: 10.3%;
  }

  .Terms-of-Service-text-style {
    margin-top: 6%;
    margin-bottom: 6%;
  }

  .signUp-container {
    background-color: white !important;
    overflow-y: auto !important;
    max-height: 100vh !important;
  }
}
