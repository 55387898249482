.backpress-container {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;
}

.backpress-back-icon {
}

@media (max-width: 768px) {
    .backpress-container {
    }
}