.product-mark-sold {
  display: inline-flex;
  align-items: flex-start;
  float: right;
  padding: 14px 20px;
  gap: 10px;
  border-radius: 16px;
  background: var(--main-color-100, #fd9340);
  cursor: pointer;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}

.seller-page-comments-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.seller-page-view-all-comments-button {
  border: none;
  height: 25px;
  text-align: left;
  margin-top: 10px;
  color: #130f2684;
  font-family: "PoppinsBold";
  cursor: pointer;
  background-color: transparent;
}

.view-all-comments-button-sold-items {
  color: #fd9340 !important;
}

.product-publish {
  display: inline-flex;
  align-items: flex-start;
  float: right;
  border: none;

  padding: 14px 20px;
  gap: 10px;
  border-radius: 16px;
  background: var(--main-color-100, #fd9340);
  cursor: pointer;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  margin-right: 20px;
}

.product-publish img {
  width: 35px;
  height: 25px;
  padding: 0px 11px;
}

.seller-product-feature-edit-delete {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  margin-right: 20px;
}

.product-feature-edit {
  margin-right: 43.83px;
}

.main-action-container {
  display: flex;
  justify-content: space-between;
  width: calc(1112px - 518px);
  align-items: center;
}

.product-main-action {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .product-feature-edit {
    margin-top: 12px;
  }

  .seller-product-feature-edit-delete {
    flex-direction: column-reverse;
    margin-left: 23px;
    justify-content: start;
  }

  .main-action-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 28px 11px 0px 11px;
  }

  .product-publish {
    margin-right: 12px;
    margin-top: 28px;
  }

  .seller-page-view-all-comments-button {
    height: 20px;
    margin-top: 4px;
  }
}
