.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modalNew-content {
  width: 272.941px;
  height: 255.557px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #FFF;
}
.para-style {
  width: 177.855px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  margin-left: 15%;
  margin-top: 51.3px;
}
.buttonfirst-style {
  display: flex;
  cursor: pointer;
  width: 80px;
  height: 47px;
  padding: 14px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  margin-left: 52.3px;
  background: var(--main-color-100, #FD9340);
  color: #FFFFFF;
  /* color: #B3B1B0;
  background-color: var(--greyscale-black-background, #F8F8F8); */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  border: none;
  justify-content: center;
}

.buttonfirst-style img {
  width: 50%;
  /* height: 19px; */
}

.unclickable {
  pointer-events: none;
}

.buttonsecond-style {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 80px;
  padding: 14px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  background-color: var(--greyscale-black-background, #F8F8F8);
  color: #B3B1B0;
  margin-left: 149.59px;
  border: none;
  margin-top: -46px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
/* .buttonfirst-style:hover {
  background: var(--main-color-100, #FD9340);
  color: #FFFFFF;
} */
/* .buttonsecond-style:hover {
  background: var(--main-color-100, #FD9340);
  color: #FFFFFF;
} */
.no-style {
  text-decoration: none;
}
.back-link {
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
  color: blue;
}

.back-link:hover {
  color: orange;
  text-decoration: underline;
}