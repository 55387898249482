.root {
    overflow-y: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.logged-in-container {
    overflow-Y: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.children-content {
    flex-grow: 1;
}

.content-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}