.loading-spinner {
    width: 25px;
    height: 25px;
    border: 10px solid #fff;
    border-top: 10px solid #fd9340;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    display: flex;
    justify-content: center;
    height: 50px;
    align-items: center;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}