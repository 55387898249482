.item-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    width: 250px;
    background-color: #F8F8F8;
    border-radius: 25px;
    margin-bottom: 30px;
    margin-left: 32px;
    padding-bottom: 15px;
    cursor: pointer;
}

.catogery-name {
    color: #1A1919;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    /* 22.4px */
    padding-top: 14px;
    padding-bottom: 22px;
    margin: 0;
}

.cardImageContainer {
    height: 200px;
    width: 250px;
    display: flex;
    justify-content: center;
}

.cardImage {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
}

.image-bottom-container {
    width: 100%;
    padding-right: 50px;
}

.item-days-container {
    background-color: #FAAD14;
    display: flex;
    border-radius: 16px;
    margin-top: -27px;
    float: right;
}

.item-days-txt {
    color: white;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 700;
    line-height: 160%;
    /* 19.2px */
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 4px 14px;
}

.item-details-container {
    width: 221px;
    display: flex;
    justify-content: center;
    padding-top: 12px;
    height: 78px;
    flex-shrink: 0;
}

.item-details-container-full {
    height: 125px;
}

.parametersContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.parameters {
    font-family: 'Poppins';
    font-size: 14px;
    color: #1A1919;
    font-weight: 400;
    line-height: 160%;
    width: 221.455px;
    height: 102.956px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item-bottom-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.item-bottom-left {
    font-family: 'Poppins';
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: var(--greyscale-black-100, #000);
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 23.25px;
}

.item-bottom-right {
    font-family: 'Poppins';
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: var(--greyscale-black-100, #000);
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item-description {
    flex-shrink: 0;
    overflow-y: hidden;
    color: var(--greyscale-black-100, #000);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
    width: 221.455px;
    flex-shrink: 0;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .item-container {
        height: 287px;
        width: 167px;
        border-radius: 25px;
        margin-bottom: 14px;
        margin-left: 8px;
    }

    .catogery-name {
        font-size: 12px;
        padding-top: 16px;
        padding-bottom: 7px;
    }

    .cardImage {
        width: 150px;
        height: 150px;
        border-radius: 20px;
    }

    .cardImageContainer {
        width: 167px;
        height: 150px;
    }

    .image-bottom-container {
        padding-right: 18px;
    }

    .item-days-container {
        border-radius: 16px;
        margin-top: -24px;
    }

    .item-days-txt {
        font-size: 10px;
        padding: 4px 14px;
    }

    .item-details-container {
        width: 165px;
        height: 44px;
    }

    .item-details-container-full {
        height: 84px;
    }

    .parameters {
        font-size: 10px;
        width: 155px;
    }

    .item-bottom-container {
        width: 150px;
    }

    .item-bottom-left {
        font-size: 14px;
        margin-right: 25px;
        margin-left: 8px;
    }

    .item-bottom-right {
        font-size: 12px;
    }

    .item-description {
        width: 155px;
        font-size: 10px;
    }
}