.profile-container {
    position: absolute;
    top: 80px;
    right: 5%;
    z-index: 1;
    display: block;
    background-image: url(../../images/header/profile_modal_background.svg);
    width: 145px;
    height: 220px;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 5px;
}

.hidden {
    display: none;
}

#profile-content-container {
    margin-left: 21px;
    margin-top: 25px;
    /* background-color: red; */
    /* filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 5px;
}

.profile-content-item {
    font-size: 20px;
    font-family: Poppins;
    font-weight: 700;
    color: #B3B1B0;
    padding: 12px;
    cursor: pointer;
}

.link {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 768px) {
    .profile-container {
        top: 65px;
    }
}