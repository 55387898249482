.feedbacksucess-modal-container{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedbacksucess-container {
    width:744px;
    height: 542.747px;
    background: #F8F8F8;
    margin-top: 70px;
    margin-bottom: 70px;
}

.feedbacksucess-content{
    margin-left: 5%;
    margin-right: 5%;
}

.success-container-text {
    margin-left: 17%;
    margin-right: 17%;
    margin-top: 118.4px;
    color: var(--Color-2, #1E1E1E);
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.success-container-message{
    margin-left: 17%;
    margin-right: 17%;
    margin-bottom: 19px;
    color: var(--Color-2, #1E1E1E);
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.feedbacksucess-button-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.feedbacksucess-button{
    width: 327px;
    height: 60px;
    border: none;
    border-Radius: 30.5px;
    background-color: #FD9340;
    margin-bottom: 266.7px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--greyscale-black-white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
}

@media (max-width: 768px) {
    .feedbacksucess-container {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    .success-container-text {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 168.28px;
        font-size: 16px;
    }

    .success-container-message{
        font-size: 16px;
        margin-bottom: 83.37px;
    }

    .feedbacksucess-button{
        margin-bottom: 45.38px;
        width: 200px;
        height: 50px;
    }
}