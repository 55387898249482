.entrycontainer {
    width: 262.27px;
    height: 19px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    margin-left: 364px;
    border: none;
    outline: none;
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 16px */
    padding-left: 5px;
}

.entry-input-input-container {
    width: 100%;
}

.entry-input-with-um {
    width: 100%;
    display: flex;
}

.name-style {
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 19.2px */
    padding-left: 5px;
    width: 100px;
    height: 10px;
}

.entrysecContainer {
    width: 120.27px;
    height: 19px;
    border-radius: 5px;
    background: #fff;
    color: #B3B1B0;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 16px */
    border: none;
    outline: none;
    padding-left: 5px;
}

.dropdown-optional-style {
    width: 100%;
    height: 19px;
    flex-shrink: 0;
    border-radius: 5px;
    border: none;
    color: #5E5C5A;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    /* 16px */
}

.dropdown-optional-style:focus {
    outline: none;
}

.container-productFillslot {
    width: 423.5px;
     display: flex;
    align-items: center;
    margin-top: 16px;
    display: flex;
    padding: 5px;
    padding-right: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #F8F8F8;
    padding-inline: 10px;
    margin-bottom: 20px; 
}

.text-productFillslot {
    color: #5E5C5A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    min-width: 110px;
}

.dropdown-optional-style-m2 {
    width: 50%;
    height: 19px;
    flex-shrink: 0;
    border-radius: 5px;
    border: none;
    color: #5E5C5A;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}

.dropdown-optional-style-m2:focus {
    outline: none;
}

.arrow-down {
    margin-left: -25px;
}

.um-drop-option{
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .text-productFillslot {
        font-size: 12px;
    }
    .container-productFillslot {
        width: 267px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #F8F8F8;
    }
}