@import "normalize.css";

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.reset-container {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  flex-shrink: 0;
  background-color: gray;
  overflow: auto;
}

.reset-content {
  width: 80.74%;
  height: 75.44%;
  margin-left: 10.26%;
  margin-right: 6.71%;
  margin-top: 7.66%;
  margin-bottom: 1.88%;
  background-color: white;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  max-width: 100%;
}

.resetimg-image {
  width: 100%;
  height: 100%;
  margin: 0%;
  border-radius: 25px;
  color: inherit;
  background-color: transparent;
}

.logorp-image {
  position: absolute;
  width: 210px;
  height: 105px;
  z-index: 1;
}

.imagerp-container {
  width: 45%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-shrink: 0;
}

.form-container-resetPasswordpage {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 0% 8%;
  overflow: auto;
}

.rpForm {
  height: 100%;
  padding: 3% 0%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reset-content form {
  border: none;
}

.password-error-message {
  color: #ff4d4f;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  min-height: 20px;
  margin: 0px 6px;
}

.resetAccount {
  font-size: 30px;
  margin: 0;
  line-height: 1.2;
  font-family: "PoppinsSemiBold";
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 4%;
  @media (min-height: 950px) {
    width: calc(66% + 30px);
    text-align: center;
  }
}

.forminputs {
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.resetButton {
  width: calc(66% + 30px);
  height: 50px;
  background-color: #b3b1b0;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins";
  color: #fff;
  border: none;
  border-radius: 30.05px;
  cursor: auto;
  margin-top: 6%;
}

.resetButton.active {
  background-color: #fd9340;
  cursor: pointer;
}

.haveanaccount-text {
  font-size: 11px;
  font-family: "Poppins";
  padding: 0;
  width: 66%;
  padding: 0px 15px;
  margin-block-start: 6%;
  margin-block-end: 0px;
  text-align: center;
}

.haveanaccount-link {
  color: #fd9340;
  text-decoration: none;
}

.reset-content input[type="email"] {
  appearance: none;
  border: 1px solid #d9d9d9;
}

.form-container::-webkit-scrollbar {
  width: 0px;
}

.password-input-container {
  width: 66%;
  padding: 15px;
  border: 1px solid #e5e4e3;
  font-family: "Poppins";
  border-radius: 16px;
  position: relative !important;
}

.reset-content form input {
  width: 90%;
  outline: none;
  border: none;
  color: #b3b1b0;
  height: 100%;
  padding-inline: 0px;
}

.resetpassword-eye-icon {
  position: absolute;
  cursor: pointer;
  color: #b3b1b0;
  cursor: pointer;
  position: absolute;
  right: 4%;
  transform: translateY(-50%);
  top: 50%;
}

#reset-password-confirm-password-container {
  margin-bottom: 6%;
}

@media (max-width: 768px) {
  body {
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: white;
  }

  .reset-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-left: 0px;
    margin: 0%;
    overflow-y: scroll;
    border-radius: 0px;
  }

  .reset-content::-webkit-scrollbar {
    width: 0px;
  }

  .reset-container {
    width: 100%;
    height: 100%;
  }

  .imagerp-container {
    width: 100%;
  }

  .password-input-container {
    width: 90%;
    height: 36px;
  }

  .resetimg-image {
    object-fit: cover;
    height: 202px;
    width: 100%;
    margin-bottom: auto;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
  }

  .logorp-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 210px;
    height: 105px;
    z-index: 1;
  }

  .reset-content form input {
    width: 90%;
    height: 36px;
    margin-right: 10%;
  }

  .form-container-resetPasswordpage {
    overflow: unset;
    margin: 0% 5%;
  }

  .rpForm {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 0px;
  }

  .resetAccount {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .resetButton {
    margin-top: 7%;
    width: calc(90% + 30px);
    height: calc(36px + 30px);
  }

  .password-error-message {
    width: 100%;
  }

  #reset-password-confirm-password-container {
    margin-bottom: 7%;
  }

  .haveaccount-text {
    width: 90%;
    margin-top: 10.3%;
  }

  .haveanaccount-text {
    width: 90%;
    margin-top: 10.3%;
  }
}

@media (min-width: 1800px) {
  .resetAccount {
    margin-top: 37px;
  }
}

@media (min-width: 769px) and (max-width: 867px) {
  .eye-icon {
    margin-left: -14%;
  }
}
