@import "normalize.css";

.forgotmodal-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: gray;
}

.forgotpassword-loginlink {
  color: #fd9340;
  font-weight: 700;
  font-size: 12px;
}

.forgotmodal-content {
  width: 80.74%;
  height: 75.44%;
  margin: 7.66%;
  background-color: white;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  max-width: 100%;
}

.getstarted-loading-inforgotpw {
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 60px;
  max-height: 100%;
}

.main-image {
  min-width: 100%;
  max-height: 600px;
  border-radius: 25px;
}

.logofw-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 210px;
  height: 105px;
  z-index: 1;
}

.fpScrap-image-login {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  margin: 0%;
  filter: blur(0);
  color: inherit;
  background-color: transparent;
}

.imagefw-container {
  width: 45%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-shrink: 0;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
}

.form-container-forgotpw {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  margin: 0% 8%;
  overflow: auto;
}

.fwForm {
  height: 100%;
  padding: 3% 0%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-height: 950px) {
    padding: 10% 0%;
  }
}

.forgotmodal-content form {
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.forgotPassword {
  font-size: 30px;
  margin: 0;
  color: #000;
  margin-bottom: 10px;
  margin-top: 4%;
  font-family: "PoppinsSemiBold";
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  @media (min-height: 950px) {
    width: calc(66% + 30px);
    text-align: center;
  }
}

.forgotmodal-content form label {
  width: 327px;
  height: 60px;
  flex-shrink: 0;
  font-family: "Poppins";
}

.forgotmodal-content form input {
  width: 66%;
  padding: 15px;
  margin-bottom: 0px;
  color: #b3b1b0;
  font-family: "Poppins";
  border: 1px solid #e5e4e3;
  border-radius: 16px;
  outline: none;
}

.verifyForgotButton {
  width: calc(66% + 30px);
  height: 50px;
  flex-shrink: 0;
  background-color: #b3b1b0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  font-family: "Poppins";
  line-height: 1;
  border: none;
  border-radius: 30.5px;
}

.verifyForgotButton.active {
  background-color: #fd9340;
}

.haveaccount-text {
  font-size: 11px;
  font-family: "Poppins";
  padding: 0;
  width: 66%;
  padding: 0px 15px;
  margin-block-start: 6%;
  margin-block-end: 0px;
  text-align: center;
}

.customCursorPointer {
  cursor: pointer;
}

.customCursorDefault {
  cursor: default;
}

.haveaccount-link {
  color: #fd9340;
  text-decoration: none;
}

.forgotmodal-content input[type="email"] {
  appearance: none;
  border: 1px solid #d9d9d9;
  margin-top: 24%;
  margin-bottom: 24%;
}

@media (max-width: 768px) {
  .forgotmodal-content {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-left: 0px;
    margin: 0%;
    overflow-y: auto;
    border-radius: 0px;
  }

  .forgotmodal-content input[type="email"] {
    margin-top: 24%;
    margin-bottom: 24%;
  }

  .forgotmodal-content::-webkit-scrollbar {
    width: 0px;
  }

  .enteryouremailtext {
    margin-left: 9px;
  }

  .forgotmodal-container {
    background-color: white;
  }

  .imagefw-container {
    width: 100%;
  }

  .fpScrap-image-login {
    object-fit: cover;
    height: 202px;
    width: 100%;
    margin-bottom: auto;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
  }

  .logofw-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 210px;
    height: 105px;
    z-index: 1;
  }

  .fwForm {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 0px;
  }

  .form-container-forgotpw {
    overflow: unset;
    margin: 0% 5%;
  }

  .forgotPassword {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .forgotmodal-content form input {
    width: 90%;
    height: 36px;
  }

  .forgotmodal-container {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    display: flex;
  }

  .forgotmodal-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .fwForm {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .verifyForgotButton {
    width: calc(90% + 30px);
    height: calc(36px + 30px);
  }

  .haveaccount-text {
    width: 90%;
    margin-top: 10.3%;
  }

  .enteryouremailtext {
    color: var(--greyscale-black-30, #b3b1b0);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
  }
}
