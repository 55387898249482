.awesome-slider {
  width: 344px;
  height: 345px;
  z-index: 0;
  border-radius: 24px;
  margin-bottom: 66px;
  background-color: white;
}

.slider-image {
  width: fit-content;
  height: fit-content;
  max-width: 344px;
  max-height: 345px;
  /* object-fit: cover; */
  border-radius: 24px;
}
.image-containers {
  width: 344px;
  height: 345px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.awssld__wrapper {
  border-radius: 24px;
}

.awesome-slider.awssld__content.awssld--active {
  transition: background-color 0.3s ease;
  border-radius: 24px;
}

@media (max-width: 768px) {
  .awesome-slider {
    width: 200px;
    height: 200.581px;
    flex-shrink: 0;
  }
  .slider-image {
    max-width: 200px;
    object-fit: cover;
  }
  .image-containers {
    width: 200px;
    height: 200.581px;
  }
}